import api from "Services/Api";
import { formatToCurrency } from "Services/Utils";
import { DataTableResult } from "Types/DataTableResult";
import { CreateDealModel } from "Types/Deal/CreateDealModel";
import { DealExportModel } from "Types/Deal/DealExportModel";
import { DealExportRowModel } from "Types/Deal/DealExportRowModel";
import { DealModel } from "Types/Deal/DealModel";
import { DealReturnModel } from "Types/Deal/DealReturnModel";
import { DealSearchModel } from "Types/Deal/DealSearchModel";
import { DealStatsModel } from "Types/Deal/DealStatsModel";
import { DealStatsSearchModel } from "Types/Deal/DealStatsSearchModel";
import { DealStatusCountModel } from "Types/Deal/DealStatusCountModel";
import { SendDealsModel } from "Types/Deal/SendDealsModel";
import { SendDealsResponseModel } from "Types/Deal/SendDealsResponseModel";
import { UpdateDealModel } from "Types/Deal/UpdateDealModel";
import { PagingModel } from "Types/PagingModel";

const create = async (model: CreateDealModel): Promise<DealModel> => {
    return api.post<DealModel>(`deal`, model);
};

const exportDeals = async (model: DealExportModel): Promise<DealExportRowModel[]> => {
    return api.put(`deal/export`, model);
};

const search = async (data: PagingModel): Promise<DataTableResult<DealReturnModel>> => {
    return api.get(`deal`, data).then((res: DataTableResult<DealReturnModel>) => {
        res.data.forEach(d => {
            d.dealValueString = formatToCurrency(d.dealValue);
        });
        return res;
    });
};

const getByLeadId = async (leadId: number): Promise<DealReturnModel> => {
    return api.get<DealReturnModel>(`deal/lead/${leadId}`);
};

const getDealStats = async (model: DealStatsSearchModel): Promise<DealStatsModel> => {
    return api.get<DealStatsModel>(`deal/stats`, model);
};

const getByDealId = async (dealId: number): Promise<DealReturnModel> => {
    return api.get<DealReturnModel>(`deal/${dealId}`);
};

const send = async (data: SendDealsModel): Promise<SendDealsResponseModel> => {
    return api.put(`deal/send`, data);
};

const update = async (dealId: number, data: UpdateDealModel): Promise<DealReturnModel> => {
    return api.put(`deal/${dealId}`, data);
};

const getDealCountByStatus = async (): Promise<DealStatusCountModel[]> => {
    return api.get(`deal/count/status`);
};

const exportSearch = async (model: DealSearchModel): Promise<File> => {
    return api.getFileDownload(`deal/export`, model);
};

const dealService = {
    create,
    exportDeals,
    exportSearch,
    getByDealId,
    getByLeadId,
    getDealCountByStatus,
    getDealStats,
    search,
    send,
    update,
};

export default dealService;
