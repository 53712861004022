import { faCircleCheck, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { G3Input, G3Toggle, G3Tooltip, IconButton } from "@g3r-developers/g3-common";
import { useCallback, useMemo } from "react";
import { Col, Row, Spinner } from "reactstrap";
import { AiGenerate } from "Types/Ai/AiGenerate";
import styles from "./GenerateAiAppraisalRow.module.scss";

interface GenerateAiAppraisalRowProps {
    model: AiGenerate;
    modelChanged(newModel: AiGenerate): void;
    remove(uuid: string): void;
}

const GenerateAiAppraisalRow = ({ model, modelChanged, remove }: GenerateAiAppraisalRowProps) => {
    const handleVrmChanged = useCallback(
        (newVal: string) => {
            modelChanged({ ...model, vrm: newVal });
        },
        [model, modelChanged]
    );

    const handleMileageChanged = useCallback(
        (newVal: number) => {
            modelChanged({ ...model, mileage: newVal });
        },
        [model, modelChanged]
    );

    const handleMobileChanged = useCallback(
        (newVal: string) => {
            modelChanged({ ...model, mobile: newVal });
        },
        [model, modelChanged]
    );

    const handleSendSmsChanged = useCallback(
        (newVal: boolean) => {
            modelChanged({ ...model, sendSms: newVal });
        },
        [model, modelChanged]
    );

    const handleRemove = useCallback(() => {
        remove(model.uuid);
    }, [model.uuid, remove]);

    const disabled = useMemo(() => {
        return model.loading || model.success;
    }, [model.loading, model.success]);

    return (
        <Row className={`${styles.inputRow}`}>
            <Col xs={3}>
                <G3Input
                    disabled={disabled}
                    placeholder="Enter VRM"
                    type="text"
                    value={model.vrm}
                    onChange={handleVrmChanged}
                />
            </Col>
            <Col xs={3}>
                <G3Input
                    disabled={disabled}
                    placeholder="Enter Mileage"
                    type="number"
                    value={model.mileage}
                    onChange={handleMileageChanged}
                />
            </Col>
            <Col xs={3}>
                <G3Input
                    disabled={disabled}
                    placeholder="Enter Mobile Number"
                    type="text"
                    value={model.mobile}
                    onChange={handleMobileChanged}
                />
            </Col>
            <Col xs={3}>
                <div className={`${styles.actionCell} d-flex align-items-end`}>
                    <div className="d-flex flex-column">
                        <G3Toggle disabled={disabled} active={model.sendSms} onToggle={handleSendSmsChanged} />
                    </div>

                    {!model.success && (
                        <IconButton
                            disabled={disabled}
                            faIconString="fa fa-trash-alt"
                            variant="danger"
                            onClick={handleRemove}
                        />
                    )}

                    {model.loading && (
                        <>
                            <Spinner />
                        </>
                    )}

                    {model.success && (
                        <>
                            <FontAwesomeIcon icon={faCircleCheck} className="text-success" size="2x" />
                        </>
                    )}

                    {model.error && (
                        <>
                            <G3Tooltip text={model.error}>
                                <FontAwesomeIcon icon={faTimesCircle} className="text-danger" size="2x" />
                            </G3Tooltip>
                        </>
                    )}
                </div>
            </Col>
        </Row>
    );
};

export default GenerateAiAppraisalRow;
