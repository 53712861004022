import { DirectionsRenderer, GoogleMap, withGoogleMap, withScriptjs } from "react-google-maps";
import useDmwToasts from "Hooks/UseDmwToasts";
import { GeoCoordinates } from "Types/GeoCoordinates";
import { useCallback, useEffect, useState } from "react";

const google = window.google;

const googleMapKey =
    "https://maps.googleapis.com/maps/api/js?key=" +
    import.meta.env.VITE_MAPS_KEY +
    "&v=3.exp&libraries=geometry,drawing,places";

interface DirectionsMapProps {
    start: GeoCoordinates;
    destination: GeoCoordinates;
    onRoute: any;
}

export default function DirectionsMap({ start, destination, onRoute }: DirectionsMapProps) {
    return (
        <DirectionsGoogleMap
            googleMapURL={googleMapKey}
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `400px` }} />}
            mapElement={<div style={{ height: `100%` }} />}
            start={start}
            destination={destination}
            onRoute={onRoute}
        />
    );
}

const DirectionsGoogleMap = withScriptjs(
    withGoogleMap((props: any) => {
        const [directions, setDirections] = useState<google.maps.DirectionsResult>(undefined);
        const [error, setError] = useState<boolean>(false);
        const DirectionsService = new google.maps.DirectionsService();

        const { errorToast } = useDmwToasts();

        const processDirections = useCallback(
            (result: google.maps.DirectionsResult) => {
                if (!result || result.routes.length === 0) {
                    return;
                }

                props.onRoute(result.routes[0].legs[0].distance.text);
            },
            [props.onRoute]
        );

        const updateMap = useCallback(
            (start: GeoCoordinates, destination: GeoCoordinates) => {
                if (
                    start?.latitude === undefined ||
                    start?.longitude === undefined ||
                    destination?.latitude === undefined ||
                    destination?.longitude === undefined
                ) {
                    return;
                }

                DirectionsService.route(
                    {
                        origin: new google.maps.LatLng(Number(start.latitude), Number(start.longitude)),
                        destination: new google.maps.LatLng(
                            Number(destination.latitude),
                            Number(destination.longitude)
                        ),
                        travelMode: google.maps.TravelMode.DRIVING,
                        unitSystem: google.maps.UnitSystem.IMPERIAL,
                    },
                    (result, status) => {
                        if (status === google.maps.DirectionsStatus.OK) {
                            setDirections(result);
                            processDirections(result);
                        } else {
                            errorToast(`Error fetching directions ${status ? `: ${status}` : ""}`);
                            setError(true);
                        }
                    }
                );
            },
            [errorToast, processDirections]
        );

        useEffect(() => {
            if (error) {
                return;
            }

            updateMap(props.start, props.destination);
        }, [error, props.start, props.destination, updateMap]);

        return (
            <GoogleMap
                defaultZoom={7}
                defaultCenter={new google.maps.LatLng(props.start.latitude, props.start.longitude)}
            >
                {directions && <DirectionsRenderer directions={directions} />}
            </GoogleMap>
        );
    })
);
