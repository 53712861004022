import { useCallback } from "react";
import { useSelector } from "react-redux";
import { ROLES } from "Types/Enums/Roles";
import { SessionStoreState } from "Types/SessionStoreState";

export default function useRoles() {
    const userRoles = useSelector((state: SessionStoreState) => state?.session?.user?.roles ?? []);

    const hasRoles = useCallback((roles: ROLES[]) => roles.some(role => userRoles.includes(role)), [userRoles]);

    return {
        hasRoles,
        roles: userRoles,
    };
}
