import { faCheckCircle, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { G3Card } from "@g3r-developers/g3-common";
import { activityTypeIdToFaIcon } from "Services/Utils/ActivityUtils";
import { ContactActivityType } from "Types/Enums/ContactActivityType";
import { LeadNoteModel } from "Types/LeadNote/LeadNoteModel";
import { useCallback, useMemo, useState } from "react";
import { Col, Row } from "reactstrap";
import UpdateContactActivityModal from "./UpdateContactActivityModal";

interface ContactActivityCardProps {
    activity: LeadNoteModel;
    reload: (outcomeId: number) => void;
}

export default function ContactActivityCard({ activity, reload }: ContactActivityCardProps) {
    const [showUpdateModal, setShowUpdateModal] = useState<boolean>(false);

    const toggleShowUpdateModal = useCallback(() => setShowUpdateModal(p => !p), []);

    const icon = useMemo(() => {
        return activityTypeIdToFaIcon(activity.activityType.lookupId);
    }, [activity?.activityType?.lookupId]);

    const toDoCompletedIcon = useMemo(() => {
        if (activity.activityType.lookupId === ContactActivityType.Note) {
            return faCheckCircle;
        }
        return activity.actionedDate === null ? faExclamationCircle : faCheckCircle;
    }, [activity.actionedDate, activity.activityType.lookupId]);

    const toDoCompletedColor = useMemo(() => {
        if (activity.activityType.lookupId === ContactActivityType.Note) {
            return "green";
        }
        return activity.actionedDate === null ? "red" : "green";
    }, [activity.actionedDate, activity.activityType.lookupId]);

    const userName = useMemo(() => {
        if (!activity) {
            return "";
        }

        if (activity.actionedUser !== null) {
            return activity.actionedUser.fullName;
        }
        if (activity.assignedUser !== null) {
            return activity.assignedUser.fullName;
        }
        return "Waiting for Assignment";
    }, [activity]);

    const date = useMemo(() => {
        if (activity.actionedDate !== null) {
            return activity.actionedDateString;
        }

        return activity.activityDateString;
    }, [activity]);

    const cardClass = useMemo(() => {
        if (activity.activityType.lookupId === ContactActivityType.Note) {
            return "note-txt-area";
        }
        return "";
    }, [activity?.activityType?.lookupId]);

    return (
        <>
            <Row className="m-0 mb-3">
                <Col md="11">
                    <G3Card className={`${cardClass}`}>
                        <div className="d-flex flex-column">
                            <span className="text-muted">
                                <FontAwesomeIcon icon={icon} />
                                <FontAwesomeIcon className="ms-2" icon={toDoCompletedIcon} color={toDoCompletedColor} />
                                &nbsp;{date} &bull; {userName}
                                {activity.activityOutcome && <> &bull; {activity.activityOutcome.displayName}</>}
                            </span>
                            <span className="mt-3">{activity.text}</span>
                        </div>
                    </G3Card>
                </Col>
            </Row>

            {showUpdateModal && (
                <UpdateContactActivityModal activity={activity} reload={reload} toggle={toggleShowUpdateModal} />
            )}
        </>
    );
}
