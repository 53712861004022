import api from "Services/Api";
import { CreateLeadNoteModel } from "Types/LeadNote/CreateLeadNoteModel";
import { LeadNoteModel } from "Types/LeadNote/LeadNoteModel";
import { UpdateLeadNoteModel } from "Types/LeadNote/UpdateLeadNoteModel";

const BaseUrl = "lead/note";

const assign = async (leadNoteId: number): Promise<LeadNoteModel> => {
    return api.patch<LeadNoteModel>(`${BaseUrl}/${leadNoteId}/assign`, undefined);
};

const create = async (data: CreateLeadNoteModel): Promise<LeadNoteModel> => {
    return api.post<LeadNoteModel>(`${BaseUrl}`, data);
};

const _delete = async (leadNoteId: number): Promise<LeadNoteModel> => {
    return api._delete<LeadNoteModel>(`${BaseUrl}/${leadNoteId}`);
};

const getById = async (leadNoteId: number): Promise<LeadNoteModel> => {
    return api.get<LeadNoteModel>(`${BaseUrl}/${leadNoteId}`);
};

const getNotesByLead = async (leadId: number): Promise<LeadNoteModel[]> => {
    return api.get<LeadNoteModel[]>(`${BaseUrl}/by-lead/${leadId}`);
};

const update = async (leadNoteId: number, data: UpdateLeadNoteModel): Promise<LeadNoteModel> => {
    return api.patch<LeadNoteModel>(`${BaseUrl}/${leadNoteId}`, data);
};

const leadNoteService = {
    assign,
    create,
    _delete,
    getById,
    getNotesByLead,
    update,
};

export default leadNoteService;
