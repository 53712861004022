import GenerateAiAppraisals from "Components/Ai/GenerateAiAppraisals";
import { Col, Row } from "reactstrap";

const AiPage = () => {
    return (
        <>
            <Row className="algin-items-center">
                <Col xs={12} lg={8} className="m-auto">
                    <GenerateAiAppraisals />
                </Col>
            </Row>
        </>
    );
};

export default AiPage;
