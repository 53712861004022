import api from "Services/Api";
import { LeadPrioritySearchModel } from "Types/lead/Priority/LeadPrioritySearchModel";
import { LeadPriorityTableRow } from "Types/lead/Priority/LeadPriorityTableRow";

const BaseUrl = `lead/priority`;

const getTable = (model: LeadPrioritySearchModel): Promise<LeadPriorityTableRow[]> => {
    return api.get(`${BaseUrl}`, model);
};

const sendPreCallSms = (leadId: number): Promise<void> => {
    return api.post(`${BaseUrl}/${leadId}/sms`);
};

const LeadPriorityService = {
    getTable,
    sendPreCallSms,
};

export default LeadPriorityService;
