import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import useRoles from "Hooks/UseRoles";
import { UserSettingsContext } from "Providers/UserSettings/UserSettingsProvider";
import authService from "Services/AuthService";
import OrganisationService from "Services/Organisation/OrganisationService";
import { ROLES } from "Types/Enums/Roles";
import { useCallback, useContext, useEffect, useState } from "react";
import { Link, NavLink, To, useNavigate } from "react-router-dom";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import "./Sidebar.css";
import SidebarLinks from "./SidebarLinks/SidebarLinks";

export interface SidebarNavLink {
    element?: JSX.Element;
    icon?: IconDefinition;
    label: string;
    spacer?: boolean;
    to?: To;
}

interface SidebarProps {
    children: JSX.Element;
    username: string;
}

export const Sidebar = ({ children, username }: SidebarProps): React.ReactElement => {
    const navigate = useNavigate();

    const { state, dispatch } = useContext(UserSettingsContext);
    const { organisation, logoLoadedHash } = state;

    const [sidebarOpen, toggleSidebar] = useState(false);

    const handleLogout = () => {
        authService.logout().then(() => {
            navigate("/login");
        });
    };

    const { hasRoles } = useRoles();

    const getOrganisation = useCallback(async () => {
        try {
            const res = await OrganisationService.getUserOrganisationSettings();
            dispatch({ type: "SET_ORGANISATION", organisation: res });
        } catch {
            // Do nothing
        }
    }, [dispatch]);

    useEffect(() => {
        if (!organisation) {
            getOrganisation();
        }
    }, [getOrganisation, organisation]);

    return (
        <>
            <aside
                className={sidebarOpen ? "left-sidebar text-center pt-4" : "left-sidebar text-center pt-4 open-sidebar"}
            >
                <div
                    onClick={() => toggleSidebar(!sidebarOpen)}
                    className={sidebarOpen ? "sidebar-chevron" : "sidebar-chevron sidebar-rotate"}
                >
                    <i className="fas fa-chevron-right chevron"></i>
                </div>
                <div className="scroll-sidebar">
                    {organisation && organisation.logoStorageUrl && (
                        <Link to="/">
                            <img
                                alt={"Organisation logo"}
                                key={logoLoadedHash}
                                src={`${organisation.logoStorageUrl}?${logoLoadedHash}`}
                                className="sidebar-logo mb-2"
                            />
                        </Link>
                    )}

                    <SidebarLinks />

                    <div className="sidebar-dropdown">
                        <nav className="mt-2">
                            <ul className={"sidebar-list"}>
                                <li className="sidebar-item sidebar-dropdown-item">
                                    <UncontrolledDropdown className="sidebar-dropdown-link">
                                        <DropdownToggle tag="span" caret>
                                            <i className="fa fa-user"></i>
                                            <span className="ms-3">{username}</span>
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem>
                                                <NavLink to="/account">My Account</NavLink>
                                            </DropdownItem>
                                            {hasRoles([ROLES.SYSTEM_ADMINISTRATOR]) && (
                                                <>
                                                    <DropdownItem>
                                                        <NavLink to="/organisations">Organisations</NavLink>
                                                    </DropdownItem>
                                                </>
                                            )}
                                            {hasRoles([ROLES.ORGANISATION_MODERATOR, ROLES.SYSTEM_ADMINISTRATOR]) && (
                                                <>
                                                    <DropdownItem>
                                                        <NavLink to="/users">Organisation Users</NavLink>
                                                    </DropdownItem>
                                                    <DropdownItem>
                                                        <NavLink to="/branches">Branches</NavLink>
                                                    </DropdownItem>
                                                    <DropdownItem>
                                                        <NavLink to={"/developers"}>Developers</NavLink>
                                                    </DropdownItem>
                                                </>
                                            )}
                                            <DropdownItem>
                                                <a
                                                    href="https://help.g3signal.co.uk/en"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    Help & Support
                                                </a>
                                            </DropdownItem>
                                            <DropdownItem onClick={handleLogout}>Logout</DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </li>
                            </ul>
                        </nav>

                        <img
                            src="https://media.g3r.co.uk/signal/powered-by-signal.png"
                            alt="Powered by Signal"
                            className="w-75 mb-3"
                        />
                        <br />
                    </div>
                </div>
            </aside>

            <div className="page-wrapper p-3">{children}</div>
        </>
    );
};

export default Sidebar;
