import { useCallback, useEffect, useMemo, useState } from "react";
import { Navigate, Outlet, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import authService from "Services/AuthService";
import Sidebar from "./Sidebar";

interface PrivateRouteProps {
    allowAccessToken?: boolean;
    roles?: string[];
}

export default function PrivateRoute({ allowAccessToken, roles }: PrivateRouteProps) {
    const [authenticated, setAuthenticated] = useState<boolean>(false);
    const [checked, setChecked] = useState<boolean>(false);
    const [username, setUsername] = useState<string>("");

    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const accessToken = useMemo(() => searchParams.get("accessToken"), [searchParams]);

    const getRedirectUrl = useCallback(() => encodeURIComponent(`${location.pathname}${location.search}`), [location]);

    useEffect(() => {
        authService
            .getUser()
            .then(s => {
                if (s) {
                    return s;
                }

                if (!s && allowAccessToken && accessToken) {
                    return authService.authenticateWithAccessToken(accessToken);
                }

                throw new Error("User is not authenticated.");
            })
            .then(s => {
                if (new Date(s.expires) < new Date()) {
                    authService.logout().then(() => {
                        navigate("/");
                    });
                    setChecked(true);
                    return;
                }

                setAuthenticated(!s.mfaRequired);

                setUsername(s.fullName);

                if (!roles) {
                    setChecked(true);
                    return;
                }

                if (!s.roles) {
                    setAuthenticated(false);
                    setChecked(true);
                    return;
                }

                setChecked(roles.some(role => s.roles.includes(role)));
            })
            .catch(() => {
                searchParams.delete("accessToken");
                setSearchParams(searchParams, { replace: true });
                setAuthenticated(false);
                setChecked(true);
            });
    }, [accessToken, allowAccessToken, navigate, roles, searchParams, setSearchParams]);

    const canView = useMemo(() => checked && authenticated, [checked, authenticated]);

    return (
        checked && (
            <>
                {canView ? (
                    <Sidebar username={username}>
                        <Outlet />
                    </Sidebar>
                ) : (
                    <Navigate to={`/login?redirectUrl=${getRedirectUrl()}`} />
                )}
            </>
        )
    );
}
