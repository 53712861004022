import { faStopwatch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateTime } from "luxon";
import { useMemo } from "react";

interface TimeToFirstResponseValueProps {
    added: string;
    firstResponse?: string;
}

export const TimeToFirstResponseValue = ({ added, firstResponse }: TimeToFirstResponseValueProps) => {
    const val = useMemo(() => {
        let diff = undefined;
        if (firstResponse === null) {
            diff = DateTime.now().diff(DateTime.fromISO(added), ["days", "hours", "minutes"]);
        } else {
            diff = DateTime.fromISO(firstResponse).diff(DateTime.fromISO(added), ["hours", "minutes"]);
        }

        return diff.toHuman({
            unitDisplay: "narrow",
            listStyle: "narrow",
            minimumIntegerDigits: 1,
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
            trailingZeroDisplay: "stripIfInteger",
        });
    }, [added, firstResponse]);

    const color = useMemo(() => {
        if (firstResponse === null) {
            return "text-danger";
        }
    }, [firstResponse]);
    return (
        <>
            <span className={color}>
                {val}
                {firstResponse === null && <FontAwesomeIcon className="ms-2" icon={faStopwatch} />}
            </span>
        </>
    );
};
