import "@g3r-developers/g3-common/dist/main.css"; //Import after index.scss
import { PermissionGroups } from "Constants/PermissionGroups";
import { useSession } from "Hooks/UseSession";
import "index.scss";
import DialerIndex from "Pages/Dialer/DialerIndex";
import UserSettingsProvider from "Providers/UserSettings/UserSettingsProvider";
import React, { lazy, Suspense, useEffect } from "react";
import { CookiesProvider } from "react-cookie";
import { connect } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import PrivateRoute from "routes/PrivateRoute";
import RedirectIfAuthedRoute from "routes/RedirectIfAuthedRoute";
import { AppProps } from "Types/AppProps";
import { ROLES } from "Types/Enums/Roles";

const AccountPage = lazy(() => import("./Pages/AccountPage"));
const AppraisalPortal = lazy(() => import("./Components/Appraisal/AppraisalPortal"));
const AutoDeclineRulesPage = lazy(() => import("./Pages/AutoDeclineRule/AutoDeclineRulesPage"));
const BranchesPage = lazy(() => import("./Pages/Organisation/Branch/BranchesPage"));
const BranchViewPage = lazy(() => import("./Pages/Organisation/Branch/BranchViewPage"));
const CapRequiredTaskPage = lazy(() => import("./Pages/Tasks/CapRequiredTaskPage/CapRequiredTaskPage"));
const CbcIndex = lazy(() => import("./Components/Cbc/CbcIndex"));
const ChildOrganisationsPage = lazy(() => import("./Pages/ChildOrganisations/ChildOrganisationsPage"));
const ComponentsPage = lazy(() => import("./Pages/Pave/DamageMatrix/ComponentsPage"));
const ConfirmEmailPage = lazy(() => import("./Pages/ConfirmEmailPage"));
const DashboardRoute = lazy(() => import("./routes/DashboardRoute"));
const DealsPage = lazy(() => import("./Pages/DealsPage"));
const DevelopersPage = lazy(() => import("./Pages/Developers/DevelopersPage"));
const DiaryPage = lazy(() => import("./Pages/DiaryPage"));
const Factors = lazy(() => import("./Pages/Factors"));
const FactorsView = lazy(() => import("./Pages/FactorsView"));
const ForgotPasswordPage = lazy(() => import("./Pages/ForgotPasswordPage"));
const G3AuctionLeadsPage = lazy(() => import("./Pages/G3AuctionLeadsPage"));
const LeadsPage = lazy(() => import("./Pages/LeadsPage"));
const LeadViewPage = lazy(() => import("./Pages/LeadViewPage"));
const LoginPage = lazy(() => import("./Pages/LoginPage"));
const ManualValuationTaskPage = lazy(() => import("./Pages/Tasks/ManualValuationTaskPage/ManualValuationTaskPage"));
const NewLead = lazy(() => import("./Pages/Leads/NewLead"));
const NotFoundPage = lazy(() => import("./Pages/NotFoundPage"));
const NotificationsPage = lazy(() => import("./Pages/NotificationsPage"));
const OnboardingPage = lazy(() => import("./Pages/OnboardingPage"));
const OrganisationPage = lazy(() => import("./Pages/Organisation/OrganisationPage"));
const OrganisationsPage = lazy(() => import("./Pages/Organisation/OrganisationsPage"));
const OrgStats = lazy(() => import("./Pages/Organisation/OrgStats"));
const OutstandingTasksPage = lazy(() => import("./Pages/Tasks/OutstandingTasksPage/OutstandingTasksPage"));
const PaveTest = lazy(() => import("./Pages/PaveTest"));
const RetailLeadsPage = lazy(() => import("./Pages/RetailLeadsPage"));
const RetailSearchPage = lazy(() => import("./Pages/RetailSearchPage"));
const SetPasswordPage = lazy(() => import("./Pages/SetPasswordPage"));
const UnderwritePage = lazy(() => import("./Pages/UnderwritePage"));
const UsersPage = lazy(() => import("./Pages/UsersPage"));
const WidgetPage = lazy(() => import("./Pages/Widget/WidgetPage"));

const ManagementApp = ({ authenticated }: AppProps) => {
    return (
        <React.StrictMode>
            <CookiesProvider>
                <BrowserRouter>
                    <UserSettingsProvider>
                        <ToastProvider>
                            <AppCuesProvider authenticated={authenticated}>
                                <div className="min-100">
                                    <Suspense fallback={<div>Loading...</div>}>
                                        <Routes>
                                            <Route path="/" element={<PrivateRoute />}>
                                                <Route index element={<DashboardRoute />} />
                                                <Route path="/account" element={<AccountPage />} />
                                            </Route>

                                            <Route
                                                path="/"
                                                element={
                                                    <PrivateRoute roles={[...PermissionGroups.organisationUserRoles]} />
                                                }
                                            >
                                                <Route element={<NotificationsPage />} path="/notifications" />
                                                <Route element={<LeadsPage />} path="/leads" />
                                                <Route element={<LeadViewPage />} path="/leads/:leadId" />
                                                <Route element={<NewLead />} path="/leads/new" />
                                                <Route element={<DiaryPage />} path="/diary" />
                                                <Route element={<DialerIndex />} path="/dialer" />
                                            </Route>

                                            <Route path={"/"} element={<PrivateRoute allowAccessToken />}>
                                                <Route element={<AppraisalPortal />} path={"/appraisal-portal"} />
                                                <Route
                                                    element={<AppraisalPortal />}
                                                    path={"/appraisal-portal/:appraisalId"}
                                                />
                                            </Route>

                                            <Route
                                                path="/"
                                                element={
                                                    <PrivateRoute roles={[...PermissionGroups.permissionGroup2Roles]} />
                                                }
                                            >
                                                <Route path="/factors" element={<Factors />} />
                                                <Route element={<FactorsView />} path={"/factors/:factorId"} />
                                                <Route element={<RetailLeadsPage />} path="/retail" />
                                                <Route
                                                    element={<RetailSearchPage />}
                                                    path="/retail/search/:retailProfileId"
                                                />
                                                <Route element={<BranchesPage />} path="/branches" />
                                                <Route element={<BranchViewPage />} path="/branches/:branchId" />
                                                <Route element={<DealsPage />} path="/deals" />
                                                <Route element={<G3AuctionLeadsPage />} path={"/g3-auctions"} />
                                                <Route element={<OrgStats />} path={"/org-stats"} />
                                            </Route>

                                            <Route
                                                path="/"
                                                element={<PrivateRoute roles={[ROLES.SYSTEM_ADMINISTRATOR]} />}
                                            >
                                                <Route path="/organisations" element={<OrganisationsPage />} />
                                                <Route path="/components" element={<ComponentsPage />} />
                                            </Route>

                                            <Route
                                                path="/"
                                                element={
                                                    <PrivateRoute roles={[...PermissionGroups.permissionGroup3Roles]} />
                                                }
                                            >
                                                <Route path="/users" element={<UsersPage />} />
                                                <Route path="/developers" element={<DevelopersPage />} />
                                                <Route path="/auto-decline-rules" element={<AutoDeclineRulesPage />} />
                                                <Route path="/cbc" element={<CbcIndex />} />
                                                <Route
                                                    path="/organisations/:organisationId"
                                                    element={<OrganisationPage />}
                                                />
                                                <Route
                                                    path="/child-organisations"
                                                    element={<ChildOrganisationsPage />}
                                                />
                                            </Route>

                                            <Route
                                                path="/"
                                                element={
                                                    <PrivateRoute roles={[...PermissionGroups.permissionGroup1Roles]} />
                                                }
                                            >
                                                <Route path={"/tasks"} element={<OutstandingTasksPage />} />

                                                <Route path={"/tasks/leads"} element={<Navigate to={"/tasks"} />} />

                                                <Route
                                                    path={"/tasks/leads/:leadId/cap-required"}
                                                    element={<CapRequiredTaskPage />}
                                                />

                                                <Route
                                                    path={"/tasks/leads/:leadId/manual-valuation"}
                                                    element={<ManualValuationTaskPage />}
                                                />
                                            </Route>

                                            <Route
                                                path="/"
                                                element={<PrivateRoute roles={[ROLES.UNDERWRITE_VALUER]} />}
                                            >
                                                <Route path={"/underwrite"} element={<UnderwritePage />} />
                                            </Route>

                                            <Route path="/" element={<RedirectIfAuthedRoute />}>
                                                <Route element={<LoginPage />} path={"/login"} />
                                                <Route element={<ForgotPasswordPage />} path={"/forgot-password"} />
                                                <Route element={<SetPasswordPage />} path={"/change-password"} />
                                            </Route>

                                            <Route element={<WidgetPage />} path={"/widget/:orgName"} />
                                            <Route element={<ConfirmEmailPage />} path={"/confirm-email"} />
                                            <Route element={<PaveTest />} path={"/pave"} />
                                            <Route element={<OnboardingPage />} path={"/g3-application/:orgName"} />
                                            <Route element={<NotFoundPage />} />
                                        </Routes>
                                    </Suspense>
                                </div>
                            </AppCuesProvider>
                        </ToastProvider>
                    </UserSettingsProvider>
                </BrowserRouter>
            </CookiesProvider>
        </React.StrictMode>
    );
};

const mapState = ({ session }): AppProps => ({
    authenticated: session.authenticated && !session.user.mfaRequired,
    checked: session.checked,
    session: session,
});

export default connect(mapState)(ManagementApp);

interface AppCuesProviderProps {
    authenticated: boolean;
    children?: React.ReactNode;
}

function AppCuesProvider({ authenticated, children }: AppCuesProviderProps) {
    const { user } = useSession();

    useEffect(() => {
        if (authenticated && user) {
            window.Appcues.identify(user.userId, {});
        }
    }, [authenticated, user]);

    return <>{children}</>;
}
