import { UserSettingsActions, UserSettingsState } from "./UserSettingsProvider";

export const UserSettingsReducer = (state: UserSettingsState, action: UserSettingsActions) => {
    switch (action.type) {
        case "SET_ORGANISATION":
            return {
                ...state,
                organisation: action.organisation,
                logoLoadedHash: Date.now(),
            };
        case "SET_ORGANISATION_LOGO_URL":
            return {
                ...state,
                organisation: {
                    ...state.organisation,
                    logoStorageUrl: action.logoStorageUrl,
                },
                logoLoadedHash: Date.now(),
            };
        case "REFRESH_NOTIFICATION_COUNT":
            return {
                ...state,
                notificationCountRefreshToken: { ...state.notificationCountRefreshToken },
            };
        default:
            return state;
    }
};
