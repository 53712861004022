import DialerTable from "Components/Dialer/DialerTable";
import { useMemo } from "react";

const DialerIndex = () => {
    const date = useMemo(() => {
        return new Intl.DateTimeFormat("en-GB", {
            weekday: "long",
            month: "long",
            day: "numeric",
            year: "numeric",
        }).format(new Date());
    }, []);

    return (
        <>
            <h1 className="page-title text-bold">{date}</h1>

            <DialerTable />
        </>
    );
};

export default DialerIndex;
