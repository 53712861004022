import { G3Date, G3Dropdown, G3Input, G3TextArea, Loading } from "@g3r-developers/g3-common";
import { useSession } from "Hooks/UseSession";
import { useEffect, useState } from "react";
import { AccordionBody, AccordionHeader, AccordionItem, Col, Row, UncontrolledAccordion } from "reactstrap";
import dropdownService from "Services/DropdownService";
import { CreateLeadNoteFollowUpModel } from "Types/LeadNote/CreateLeadNoteFollowUpModel";
import { CreateLeadNoteModel } from "Types/LeadNote/CreateLeadNoteModel";

export interface AddContactActivityFollowUpErrors {
    followUpActivityDate?: string;
    followUpActivityTypeId?: string;
    followUpAssignedUserId?: string;
    followUpText?: string;
}

export interface AddContactActivityFollowUpProps {
    followUp: CreateLeadNoteFollowUpModel;
    errors: AddContactActivityFollowUpErrors;
    setActivity: React.Dispatch<React.SetStateAction<CreateLeadNoteModel>>;
}

export default function AddContactActivityFollowUp({ followUp, errors, setActivity }: AddContactActivityFollowUpProps) {
    const [userId, setUserId] = useState<number>(undefined);

    const { user } = useSession();

    useEffect(() => {
        if (!user?.userId || userId) {
            return;
        }

        setUserId(user?.userId);
    }, [user?.userId]);

    useEffect(() => {
        // default the assigned user to current userId
        if (userId) {
            setActivity(p => ({
                ...p,
                followUp: { ...p.followUp, assignedUserId: userId },
            }));
        }
    }, [userId]);

    return (
        <UncontrolledAccordion toggle={() => {}} className="mb-5" defaultOpen={"1"}>
            <AccordionItem>
                <AccordionHeader targetId="1">Follow-Up Activity</AccordionHeader>
                <AccordionBody accordionId="1">
                    {userId ? (
                        <>
                            <Row className={"flex-column"}>
                                <Col>
                                    <G3Dropdown
                                        label="Activity Type"
                                        defaultOption={followUp.activityTypeId}
                                        loadOptions={(query: string) => dropdownService.contactActivityTypes(query)}
                                        onChange={(val: number) =>
                                            setActivity(p => ({
                                                ...p,
                                                followUp: { ...p.followUp, activityTypeId: val },
                                            }))
                                        }
                                        error={errors.followUpActivityTypeId}
                                    />
                                </Col>
                                <Col>
                                    <G3Dropdown
                                        label="Assigned User"
                                        defaultOption={userId}
                                        loadOptions={(query: string) =>
                                            dropdownService.branchesUsers(user?.branchId, query)
                                        }
                                        onChange={(val: number) =>
                                            setActivity(p => ({
                                                ...p,
                                                followUp: { ...p.followUp, assignedUserId: val },
                                            }))
                                        }
                                        error={errors.followUpAssignedUserId}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <G3Date
                                        label="Activity Date"
                                        selectedDate={followUp.activityDate}
                                        onChange={(val: string) =>
                                            setActivity(p => ({ ...p, followUp: { ...p.followUp, activityDate: val } }))
                                        }
                                        min={"today"}
                                        showTime
                                        error={errors.followUpActivityDate}
                                    />
                                </Col>
                                <Col>
                                    <G3Input
                                        label="Duration (minutes)"
                                        type="number"
                                        value={followUp.duration}
                                        onChange={(val: number) =>
                                            setActivity(p => ({ ...p, followUp: { ...p.followUp, duration: val } }))
                                        }
                                    />
                                </Col>
                            </Row>
                            <Row className="flex-column">
                                <Col>
                                    <G3TextArea
                                        label="Notes"
                                        value={followUp.text}
                                        rows={4}
                                        onChange={(val: string) =>
                                            setActivity(p => ({ ...p, followUp: { ...p.followUp, text: val } }))
                                        }
                                        error={errors.followUpText}
                                    />
                                </Col>
                            </Row>
                        </>
                    ) : (
                        <Loading />
                    )}
                </AccordionBody>
            </AccordionItem>
        </UncontrolledAccordion>
    );
}
