export enum LOOKUP_TYPES {
    NA = 0,
    VehicleAgeMonthRanges = 1,
    MonthOfYear = 2,
    YesNo = 3,
    ServiceHistory = 4,
    LeadStatus = 5,
    AppliesToAll = 6,
    MotExpiry = 7,
    PreviousKeepers = 8,
    VehicleCondition = 9,
    AppraisalStatus = 10,
    FuelType = 11,
    TransmissionType = 12,
    TeamPreference = 13,
    CallMethod = 14,
    BookingStatus = 15,
    BoostValuationCalculationMethod = 16,
    DealStatus = 17,
    VehicleType = 18,
    Co2Ranges = 19,
    VehicleCalculators = 20,
    AppraisalProvider = 21,
    DealRejectionReason = 22,
    NumberOfKeys = 23,
    Bodystyle = 24,
    BranchType = 25,
    ValuationType = 26,
    DealDelayDays = 27,
    AuditType = 28,
    PrivateHireType = 29,
    VatStatus = 30,
    OrganisationAppraisalProvider = 31,
    SelectedDateType = 32,
    RunnerStatus = 33,
    CapCleanRanges = 34,
    ValuationStartingPoint = 35,
    BookingType = 36,
    BookingPreference = 37,
    LeadOrigin = 38,
    SaleType = 41,
    LeadTransferStatus = 42,
    ContactActivityType = 43,
    ContactActivityOutcome = 44,
    CbcQuestions = 45,
    LeadAttachmentType = 46,
}
