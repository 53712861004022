import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from "react-router-dom";
import { SidebarNavLink } from "../Sidebar";

interface SidebarNavItemProps {
    navLink: SidebarNavLink;
}
const SidebarNavItem = ({ navLink }: SidebarNavItemProps) => {
    return (
        <li className={"sidebar-item"} key={navLink.label}>
            {navLink.element ? (
                navLink.element
            ) : navLink.spacer ? (
                <hr className={"sidebar-spacer"} />
            ) : (
                <NavLink className={"sidebar-link"} to={navLink.to}>
                    <FontAwesomeIcon icon={navLink.icon} />

                    <span className={"ms-3"}>{navLink.label}</span>
                </NavLink>
            )}
        </li>
    );
};

export default SidebarNavItem;
