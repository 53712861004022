import GenerateAiAppraisalsForm from "./GenerateAiAppraisalsForm/GenerateAiAppraisalsForm";

const GenerateAiAppraisals = () => {
    return (
        <>
            <h1 className="text-bold text-center">Create Valuations & Send AI Appraisals</h1>

            <GenerateAiAppraisalsForm />
        </>
    );
};

export default GenerateAiAppraisals;
