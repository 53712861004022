import { sessionService } from "redux-react-session";
import { DmwAuthReturnModel } from "Types/DmwAuthReturnModel";
import { UserLoginModel } from "Types/UserLoginModel";
import api from "./Api";

const handleAuthResponse = async (auth: DmwAuthReturnModel): Promise<DmwAuthReturnModel> =>
    Promise.all([sessionService.saveSession(auth), sessionService.saveUser(auth)]).then(() => auth);

const authenticateWithAccessToken = async (token: string): Promise<DmwAuthReturnModel> =>
    handleAuthResponse({ token } as DmwAuthReturnModel)
        .then(() => api.post<DmwAuthReturnModel>(`user/login/token`))
        .then(handleAuthResponse)
        .catch(e => {
            sessionService.invalidateSession();

            return Promise.reject(e);
        });

const safeLoadSession = async (): Promise<DmwAuthReturnModel> =>
    sessionService.loadSession().catch(e => {
        if (e === "Session not found") {
            return undefined;
        }

        return Promise.reject(e);
    });

const getRoles = async (): Promise<string[]> =>
    safeLoadSession().then((session: DmwAuthReturnModel) => session?.roles ?? []);

const getUser = async (): Promise<DmwAuthReturnModel> => safeLoadSession();

const isAuthenticated = async (): Promise<boolean> =>
    safeLoadSession().then((session: DmwAuthReturnModel) => !!session?.token);

const login = async (model: UserLoginModel): Promise<DmwAuthReturnModel> =>
    api.post<DmwAuthReturnModel>("user/login", model).then(handleAuthResponse);

const logout = async (): Promise<void> => sessionService.deleteSession();

const replaceToken = async (newUser: DmwAuthReturnModel) =>
    sessionService.deleteSession().then(() => handleAuthResponse(newUser));

const authService = {
    authenticateWithAccessToken,
    getUser,
    getRoles,
    isAuthenticated,
    login,
    logout,
    replaceToken,
};

export default authService;
