export enum ROLES {
    SYSTEM_ADMINISTRATOR = "System Administrator",
    ORGANISATION_MODERATOR = "Organisation Moderator",
    ORGANISATION_SALES_MANAGER = "Organisation Sales Manager",
    ORGANISATION_APPRAISER = "Organisation Appraiser",
    TELEPHONE_AGENT = "Telephone Agent",
    NO_VALUATION_ADMINISTRATOR = "No Valuation Administrator",
    ORGANISATION_SALES_EXECUTIVE = "Organisation Sales Executive",
    UNDERWRITE_VALUER = "Underwrite Valuer",
}
