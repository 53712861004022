import { G3Date, G3Dropdown, G3Input, G3Modal, G3TextArea, G3Toggle, Loading } from "@g3r-developers/g3-common";
import useDmwToasts from "Hooks/UseDmwToasts";
import { DateTime } from "luxon";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Row } from "reactstrap";
import dropdownService from "Services/DropdownService";
import leadNoteService from "Services/LeadNoteService";
import { isEmpty, isNullOrWhiteSpace } from "Services/Utils";
import { ContactActivityOutcome } from "Types/Enums/ContactActivityOutcome";
import { LOOKUP_TYPES } from "Types/Enums/LookupTypes";
import { CreateLeadNoteFollowUpModel } from "Types/LeadNote/CreateLeadNoteFollowUpModel";
import { LeadNoteModel } from "Types/LeadNote/LeadNoteModel";
import { UpdateLeadNoteModel } from "Types/LeadNote/UpdateLeadNoteModel";
import AddContactActivityFollowUp, { AddContactActivityFollowUpErrors } from "./AddContactActivityFollowUp";

export interface UpdateContactActivityModalProps {
    activity: LeadNoteModel;
    readonly?: boolean;
    reload: (outcomeId: number) => void;
    toggle: () => void;
}

interface UpdateContactActivityErrors extends AddContactActivityFollowUpErrors {
    actionedDate?: string;
    rejectedReasonId?: string;
    text?: string;
}

export default function UpdateContactActivityModal({
    activity,
    readonly,
    reload,
    toggle,
}: UpdateContactActivityModalProps) {
    const [data, setData] = useState<UpdateLeadNoteModel>({} as UpdateLeadNoteModel);
    const [errors, setErrors] = useState<UpdateContactActivityErrors>({});

    const { successToast, errorToast } = useDmwToasts();

    useEffect(() => {
        setData({
            text: activity.text,
            activityOutcomeId: activity.activityOutcome?.lookupId,
            rejectedReasonId: activity.rejectedReason?.lookupId,
            actionedDate: activity.actionedDate ?? DateTime.now().toISO(),
            followUp: undefined,
        });
    }, [errorToast, activity]);

    const isRejected = useMemo(
        () => data.activityOutcomeId === ContactActivityOutcome.Rejected,
        [data.activityOutcomeId]
    );

    const isActioned = useMemo((): boolean => !!data.activityOutcomeId, [data.activityOutcomeId]);

    const toggleFollowUp = useCallback(() => {
        if (!data.followUp) {
            setData(p => ({ ...p, followUp: { activityDate: DateTime.now().toISO() } as CreateLeadNoteFollowUpModel }));
        } else {
            setData(p => ({ ...p, followUp: undefined }));
        }
    }, [data.followUp]);

    const checkErrors = useCallback((model: UpdateLeadNoteModel) => {
        const e: UpdateContactActivityErrors = {};

        if (model.activityOutcomeId) {
            if (model.activityOutcomeId == ContactActivityOutcome.Rejected && !model.rejectedReasonId) {
                e.rejectedReasonId = 'If the Outcome is "Rejected" then please select a Rejected Reason';
            }

            if (!model.actionedDate) {
                e.actionedDate = "If an Outcome is selected then please select an Actioned Date";
            }
        }

        if (isNullOrWhiteSpace(model.text)) {
            e.text = "Please add a note";
        }

        if (model.followUp) {
            if (!model.followUp.activityTypeId) {
                e.followUpActivityTypeId = "Please select the Activity Type";
            }

            if (!model.followUp.activityDate) {
                e.followUpActivityDate = "Please select an Activity Date";
            }

            if (isNullOrWhiteSpace(model.followUp.text)) {
                e.followUpText = "Please add a note";
            }
        }

        setErrors(e);

        return !isEmpty(e);
    }, []);

    const handleSave = useCallback(() => {
        if (readonly) {
            return;
        }

        const hasErrors = checkErrors(data);

        if (hasErrors) {
            return;
        }

        leadNoteService
            .update(activity.leadNoteId, data)
            .then(() => {
                reload(data.activityOutcomeId);
                toggle();
                successToast("Activity Updated");
            })
            .catch(errorToast);
    }, [checkErrors, data, errorToast, activity.leadNoteId, readonly, reload, successToast, toggle]);

    const title = useMemo(() => {
        return `${readonly ? "" : "Update "} Contact Activity`;
    }, [readonly]);

    const renderOutcome = useMemo(() => {
        const label = "Outcome";
        return readonly ? (
            <G3Input label={label} value={activity.activityOutcome?.displayName ?? "TBC"} readOnly />
        ) : (
            <G3Dropdown
                label={label}
                defaultOption={data.activityOutcomeId}
                loadOptions={(query: string) => dropdownService.contactActivityOutcomes(query)}
                onChange={(val: number) => setData(p => ({ ...p, activityOutcomeId: val }))}
            />
        );
    }, [activity.activityOutcome, data, readonly]);

    const renderRejectionReason = useMemo(() => {
        const label = "Rejection Reason";
        return readonly ? (
            <G3Input label={label} value={activity.rejectedReason?.displayName} readOnly />
        ) : (
            <G3Dropdown
                label={label}
                defaultOption={data.rejectedReasonId}
                loadOptions={(query: string) => dropdownService.lookups(LOOKUP_TYPES.DealRejectionReason, query)}
                onChange={(val: number) => setData(p => ({ ...p, rejectedReasonId: val }))}
                error={errors.rejectedReasonId}
            />
        );
    }, [activity.rejectedReason, data, readonly]);

    return (
        <G3Modal title={title} toggle={toggle} save={handleSave} showSave={!readonly}>
            {activity ? (
                <fieldset disabled={readonly}>
                    <Row className={"flex-column"}>
                        <Col>
                            <G3Input label={"Activity Type"} value={activity.activityType?.displayName} readOnly />
                        </Col>
                        <Col>
                            <G3Input label={"Assigned User"} value={activity.assignedUser?.fullName} readOnly />
                        </Col>
                        <Col>{renderOutcome}</Col>
                        {isRejected && <Col>{renderRejectionReason}</Col>}
                    </Row>
                    <Row>
                        <Col>
                            <G3Input label={"Activity Date"} value={activity.activityDateString} readOnly />
                        </Col>
                        <Col>
                            <G3Input label={"Duration (minutes)"} value={activity.duration} readOnly />
                        </Col>
                    </Row>
                    <Row className="flex-column">
                        {isActioned && (
                            <Col>
                                <G3Date
                                    label="Actioned Date"
                                    selectedDate={data.actionedDate}
                                    onChange={(val: string) => setData(p => ({ ...p, actionedDate: val }))}
                                    max={"today"}
                                    showTime
                                    error={errors.actionedDate}
                                    disabled={readonly}
                                />
                            </Col>
                        )}
                        <Col>
                            <G3TextArea
                                label="Notes"
                                value={data.text}
                                rows={4}
                                onChange={(val: string) => setData(p => ({ ...p, text: val }))}
                                error={errors.text}
                            />
                        </Col>
                    </Row>
                    {!activity.followUp?.leadNoteId && !readonly && (
                        <Row className="mt-3">
                            <Col>
                                <G3Toggle
                                    label="Create a Follow-Up Activity?"
                                    active={!!data.followUp}
                                    onToggle={toggleFollowUp}
                                />
                            </Col>
                            {data.followUp && (
                                <AddContactActivityFollowUp
                                    errors={errors}
                                    followUp={data.followUp}
                                    setActivity={setData}
                                />
                            )}
                        </Row>
                    )}
                </fieldset>
            ) : (
                <Loading />
            )}
        </G3Modal>
    );
}
