import { useMemo } from "react";
import { Badge } from "reactstrap";
import { LEAD_STATUS } from "Types/Enums/LeadStatus";
import { LookupModel } from "Types/LookupModel";
import "./LeadStatusCell.css";

export function LeadStatusCell({ status }: { status?: LookupModel }) {
    const badgeColor = useMemo(() => {
        if (!status) {
            return "danger";
        }

        switch (status.lookupId) {
            case LEAD_STATUS.Accepted: {
                return "success";
            }
            case LEAD_STATUS.InProgress: {
                return "info";
            }
            case LEAD_STATUS.AppraisalCompleted: {
                return "orange";
            }
            case LEAD_STATUS.AppraisalRequested: {
                return "purple";
            }
            case LEAD_STATUS.Rejected: {
                return "danger";
            }
            case LEAD_STATUS.Submitted: {
                return "warning";
            }
            default: {
                return "info";
            }
        }
    }, [status]);

    const text = useMemo(() => {
        return status?.displayName.replace(" ", "\n");
    }, [status]);

    return (
        <Badge className="align-self-center lead-status-badge" color={badgeColor} data-tag="allowRowEvents" tag={"div"}>
            {text}
        </Badge>
    );
}
