import { GTMProvider } from "@elgorditosalsero/react-gtm-hook";
import "@g3r-developers/g3-common/dist/main.css"; //Import after index.scss
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { DmwAuthReturnModel } from "Types/DmwAuthReturnModel";
import "core-js";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { combineReducers, createStore } from "redux";
import { sessionReducer, sessionService } from "redux-react-session";
import reportWebVitals from "reportWebVitals";
import "./FontAwesome";
import ManagementApp from "./ManagementApp";
import "./index.scss";

const reducer = combineReducers({
    session: sessionReducer,
});

Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.01,
    ignoreErrors: [/Failed to fetch dynamically imported module/],
});

const gtmParams = { id: "GTM-KX8VC32" };

const validateSession = (session: DmwAuthReturnModel) => {
    if (!session.expires) {
        return false;
    }

    const date = new Date(session.expires);

    if (date < new Date()) {
        return false;
    }

    // check if your session is still valid
    return true;
};

const store = createStore(reducer);
const options = {
    driver: "INDEXEDDB",
    expires: 360,
    redirectPath: "/",
    refreshOnCheckAuth: true,
    validateSession,
};

sessionService.initSessionService(store, options).then(() => {
    ReactDOM.render(
        <Provider store={store}>
            <GTMProvider state={gtmParams}>
                <ManagementApp />
            </GTMProvider>
        </Provider>,
        document.getElementById("root")
    );
});

reportWebVitals();
