import { ROLES } from "Types/Enums/Roles";

class PermissionGroups {
    static readonly organisationUserRoles: ReadonlySet<string> = new Set([
        ROLES.NO_VALUATION_ADMINISTRATOR,
        ROLES.ORGANISATION_APPRAISER,
        ROLES.ORGANISATION_MODERATOR,
        ROLES.ORGANISATION_SALES_EXECUTIVE,
        ROLES.ORGANISATION_SALES_MANAGER,
        ROLES.SYSTEM_ADMINISTRATOR,
        ROLES.TELEPHONE_AGENT,
    ]);

    static readonly noValuationAdminGroupRoles: ReadonlySet<string> = new Set([
        ROLES.NO_VALUATION_ADMINISTRATOR,
        ROLES.SYSTEM_ADMINISTRATOR,
    ]);

    static readonly permissionGroup2Roles: ReadonlySet<string> = new Set([
        ROLES.ORGANISATION_MODERATOR,
        ROLES.ORGANISATION_SALES_MANAGER,
        ROLES.SYSTEM_ADMINISTRATOR,
    ]);

    static readonly permissionGroup3Roles: ReadonlySet<string> = new Set([
        ROLES.ORGANISATION_MODERATOR,
        ROLES.SYSTEM_ADMINISTRATOR,
    ]);

    static readonly isOnlyUnderwriteValuer = (roles: string[]) => {
        const rs = roles ?? [];

        return rs.length === 1 && rs.includes(ROLES.UNDERWRITE_VALUER);
    };

    static readonly isOrganisationUser = (roles: string[]) =>
        (roles ?? []).some(r => this.organisationUserRoles.has(r));

    static readonly isNoValuationAdminGroup = (roles: string[]) =>
        (roles ?? []).some(r => this.noValuationAdminGroupRoles.has(r));
}

export { PermissionGroups };
