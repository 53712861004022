import { G3DataTable, IconButton } from "@g3r-developers/g3-common";
import DiaryUserSelect from "Components/Diary/DiaryUserSelect";
import { LeadStatusCell } from "Components/Leads/LeadStatusCell";
import { useSession } from "Hooks/UseSession";
import LeadPriorityService from "Services/LeadPriorityService";
import { dateTimeFormat } from "Services/Utils";
import ParameterUtils from "Services/Utils/ParameterUtils";
import DropdownModel from "Types/DropdownModel";
import { LeadPrioritySearchModel } from "Types/lead/Priority/LeadPrioritySearchModel";
import { LeadPriorityCallType, LeadPriorityTableRow } from "Types/lead/Priority/LeadPriorityTableRow";
import { DateTime } from "luxon";
import { useCallback, useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { DialerExpandableRow } from "./DialerExpandableRow";
import { DialerSmsButton } from "./DialerSmsButton";
import { TimeToFirstResponseValue } from "./TimeToFirstResponseValue";

const DialerTable = () => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const changeUrl = useCallback(
        (newFilters: LeadPrioritySearchModel) => {
            const search = ParameterUtils.getUrlParams(newFilters);
            setSearchParams(search, { replace: true });
        },
        [setSearchParams]
    );

    const session = useSession();

    const filters = useMemo(() => {
        return {
            userId:
                ParameterUtils.tryGetParam(searchParams, "userId") !== undefined
                    ? Number(ParameterUtils.tryGetParam(searchParams, "userId"))
                    : session.user.userId,
            perpage:
                ParameterUtils.tryGetParam(searchParams, "perpage") !== undefined
                    ? Number(ParameterUtils.tryGetParam(searchParams, "perpage"))
                    : 25,
            pagenum:
                ParameterUtils.tryGetParam(searchParams, "pagenum") !== undefined
                    ? Number(ParameterUtils.tryGetParam(searchParams, "pagenum"))
                    : 1,
            orderby:
                ParameterUtils.tryGetParam(searchParams, "orderby") !== undefined
                    ? ParameterUtils.tryGetParam(searchParams, "orderby")
                    : "added",
            orderbydir:
                ParameterUtils.tryGetParam(searchParams, "orderbydir") !== undefined
                    ? ParameterUtils.tryGetParam(searchParams, "orderbydir")
                    : "desc",
        } as LeadPrioritySearchModel;
    }, [searchParams, session.user.userId]);

    const columns = useMemo(
        () => [
            {
                name: "Call Type",
                selector: (row: LeadPriorityTableRow) =>
                    row.callType === LeadPriorityCallType.New ? "New" : "Re-Attempt",
                grow: 0.7,
            },
            {
                name: (
                    <span>
                        Priority
                        <br />
                        Score
                    </span>
                ),
                selector: (row: LeadPriorityTableRow) => row.priorityScore ?? "TBC",
                grow: 0.5,
            },
            {
                name: "Added",
                selector: (row: LeadPriorityTableRow) => DateTime.fromISO(row.added).toFormat(dateTimeFormat),
            },
            {
                name: (
                    <span className="text-center">
                        Time to
                        <br />
                        First Response
                    </span>
                ),
                cell: (row: LeadPriorityTableRow) => (
                    <TimeToFirstResponseValue added={row.added} firstResponse={row.firstResponse} />
                ),
            },
            {
                name: (
                    <span>
                        Call
                        <br />
                        Attempts
                    </span>
                ),
                selector: (row: LeadPriorityTableRow) => row.callAttempts,
                grow: 0.5,
            },
            {
                name: "VRM",
                selector: (row: LeadPriorityTableRow) => row.vrm,
            },
            {
                name: "Valuation",
                selector: (row: LeadPriorityTableRow) => row.currentValuation,
            },
            {
                name: (
                    <span className="text-center">
                        Distance to
                        <br />
                        Branch
                    </span>
                ),
                selector: (row: LeadPriorityTableRow) => `${row.distance} Miles`,
            },
            {
                name: "Assigned To",
                selector: (row: LeadPriorityTableRow) => row.assignedTo ?? "Unassigned",
            },
            {
                name: "Status",
                selector: (row: LeadPriorityTableRow) => <LeadStatusCell status={row.status} />,
            },
            {
                name: "SMS",
                selector: (row: LeadPriorityTableRow) => <DialerSmsButton leadId={row.leadId} smsSent={row.smsSent} />,
                grow: 1.25,
            },
            {
                name: "View Lead",
                grow: 0.5,
                selector: (row: LeadPriorityTableRow) => (
                    <IconButton
                        url={`/leads/${row.leadId}`}
                        targetString="_blank"
                        variant="secondary"
                        faIconString="fa fa-car"
                    />
                ),
            },
        ],
        []
    );

    const loadTableData = useCallback((model: LeadPrioritySearchModel) => {
        return LeadPriorityService.getTable(model);
    }, []);

    const handleUserChanged = useCallback(
        (newUser: DropdownModel) => {
            changeUrl({ ...filters, userId: newUser.value });
        },
        [changeUrl, filters]
    );

    const handlePageChange = useCallback(
        (page: number) => changeUrl({ ...filters, pagenum: page }),
        [changeUrl, filters]
    );

    const handlePerPageChange = useCallback(
        (perPage: number) => changeUrl({ ...filters, perpage: perPage }),
        [changeUrl, filters]
    );

    return (
        <>
            <div className="d-flex justify-content-end ">
                <DiaryUserSelect
                    userChanged={handleUserChanged}
                    selectedUser={{ value: filters.userId } as DropdownModel}
                />
            </div>

            <G3DataTable
                load={loadTableData}
                columns={columns}
                expandableRows
                expandableRowsComponent={DialerExpandableRow}
                filters={filters}
                perPageChanged={handlePerPageChange}
                pageChanged={handlePageChange}
                perPageOverride={filters.perpage}
                pageOverride={filters.pagenum}
                defaultSortAsc={filters?.orderbydir === "asc"}
                defaultSortField={filters?.orderby}
            />
        </>
    );
};

export default DialerTable;
