import api from "Services/Api";
import { formatToCurrency, isEmpty, parseNullableDate } from "Services/Utils";
import { CapModel } from "Types/CapModel";
import { ClickDealerMetircReturnModel } from "Types/ClickDealer/ClickDealerMetricReturnModel";
import { DataTableResult } from "Types/DataTableResult";
import { G3AuctionLeadModel } from "Types/lead/G3AuctionLeadModel";
import { G3AuctionLeadSearchModel } from "Types/lead/G3AuctionLeadSearchModel";
import { G3AuctionLeadStatsModel } from "Types/lead/G3AuctionLeadStatsModel";
import { G3AuctionLeadStatsSearchModel } from "Types/lead/G3AuctionLeadStatsSearchModel";
import { LeadModel } from "Types/lead/LeadModel";
import { LeadSearchFilterModel } from "Types/lead/LeadSearchFilterModel";
import { LeadSearchModel } from "Types/lead/LeadSearchModel";
import { LeadStatsModel } from "Types/lead/LeadStatsModel";
import { LeadStatsSearchModel } from "Types/lead/LeadStatsSearchModel";
import { LeadStatusCountModel } from "Types/lead/LeadStatusCountModel";
import { LeadTaskModel } from "Types/lead/LeadTaskModel";
import { LeadTaskSearchModel } from "Types/lead/LeadTaskSearchModel";
import { LeadTaskStatsModel } from "Types/lead/LeadTaskStatsModel";
import { LeadViewModel } from "Types/lead/LeadViewModel";
import { RetailLeadSearchModel } from "Types/lead/RetailLeadSearchModel";
import { TransferLeadsModel } from "Types/lead/TransferLeadsModel";
import { UpdateLeadModel } from "Types/lead/UpdateLeadModel";
import { PagingModel } from "Types/PagingModel";
import { FavouriteStatusModel } from "Types/UserLeadFavourite/FavouriteStatusModel";
import { OtherValuationModel } from "Types/Valuation/ValuationStartingPointsModel";
import { ValuationResponseModel } from "Types/ValuationResponseModel";
import { ValuationResponseTempVehicleModel } from "Types/ValuationResponseTempVehicleModel";
import { AdditionalVehicleDetailsModel } from "Types/VehicleDetails/AdditionalVehicleDetailsModel";

const assign = async (leadId: number): Promise<LeadViewModel> => {
    return api.patch<LeadViewModel>(`lead/${leadId}/assign`, {});
};

const exportSearch = async (model: LeadSearchModel): Promise<File> => {
    return api.getFileDownload(`lead/export`, model);
};

const favourite = async (leadId: number): Promise<FavouriteStatusModel> =>
    api.post<FavouriteStatusModel>(`lead/${leadId}/favourite`);

const parseCapModel = (model: CapModel): CapModel => ({
    ...model,
    capEffectiveDate: parseNullableDate(model.capEffectiveDate),
});

const getAdditionalVehicleDetails = async (leadId: number): Promise<AdditionalVehicleDetailsModel> =>
    api.get(`lead/${leadId}/vehicle/details/additional`);

const getG3AuctionLeads = async (model: G3AuctionLeadSearchModel): Promise<DataTableResult<G3AuctionLeadModel>> => {
    return api.get<DataTableResult<G3AuctionLeadModel>>(`lead/g3-auction`, model);
};

const getG3AuctionLeadStats = async (model: G3AuctionLeadStatsSearchModel): Promise<G3AuctionLeadStatsModel> => {
    return api.get<G3AuctionLeadStatsModel>(`lead/g3-auction/stats`, model);
};

const getLeadView = async (leadId: number): Promise<LeadViewModel> => {
    return api.get<LeadViewModel>(`lead/${leadId}`).then((v: LeadViewModel) => {
        v.latestBooking = !isEmpty(v.bookings)
            ? v.bookings.sort((a, b) => (b.added.valueOf() > a.added.valueOf() ? 1 : -1))[0]
            : undefined;

        if (v.generatedValuation) {
            v.generatedValuation.amountString = formatToCurrency(v.generatedValuation.amount);
            v.generatedValuation.added = new Date(v.generatedValuation.added);
        }

        v.cap = parseCapModel(v.cap);

        return v;
    });
};

const getTasks = async (model: LeadTaskSearchModel): Promise<DataTableResult<LeadTaskModel>> =>
    api.get<DataTableResult<LeadTaskModel>>(`lead/tasks`, model);

const getTaskStats = async (): Promise<LeadTaskStatsModel> => api.get<LeadTaskStatsModel>(`lead/tasks/stats`);

const search = async (model: LeadSearchModel): Promise<DataTableResult<LeadModel>> => {
    return api.get<DataTableResult<LeadModel>>("lead", model);
};

const retailSearch = async (retailProfileId: number, model: PagingModel): Promise<DataTableResult<LeadModel>> => {
    return api.get<DataTableResult<LeadModel>>(`lead/retail/${retailProfileId}`, model);
};

const getLeadFilters = (data: RetailLeadSearchModel): Promise<LeadSearchFilterModel[]> => {
    return api.post<LeadSearchFilterModel[]>(`lead/filters`, data);
};

const update = async (leadId: number, model: UpdateLeadModel): Promise<LeadViewModel> => {
    return api.put<LeadViewModel>(`lead/${leadId}`, model);
};

const getForValuation = async (leadId: number): Promise<ValuationResponseTempVehicleModel> => {
    return api.get<ValuationResponseTempVehicleModel>(`lead/${leadId}/for-valuation`);
};

const updateForValuation = async (leadId: number, model: UpdateLeadModel): Promise<ValuationResponseModel> => {
    return api.put<ValuationResponseModel>(`lead/${leadId}/for-valuation`, model);
};

const getLeadStats = async (model: LeadStatsSearchModel): Promise<LeadStatsModel> => {
    return api.get<LeadStatsModel>(`lead/stats`, model).then((s: LeadStatsModel) => {
        s.averageValuationString = formatToCurrency(s.averageValuation);
        s.valuationTotalQuotedString = formatToCurrency(s.valuationTotalQuoted);

        return s;
    });
};

const transferToCbc = async (leadId: number): Promise<LeadViewModel> => {
    return api.put<LeadViewModel>(`lead/${leadId}/transfer-to-cbc`, undefined);
};

const getLeadCountsByStatus = async (): Promise<LeadStatusCountModel[]> => {
    return api.get<LeadStatusCountModel[]>(`lead/count/status`);
};

const getOtherValuationsForLead = async (leadId: number) => {
    return api.get<OtherValuationModel>(`lead/${leadId}/other-valuations`);
};

const getMetric = async (leadId: number) => {
    return api.get<ClickDealerMetircReturnModel>(`lead/${leadId}/metric`);
};

const transferLeadsToCbc = async (model: TransferLeadsModel): Promise<void> => {
    return api.put(`lead/transfer-to-cbc`, model);
};

const acceptUnderwriteValuation = async (leadId: number): Promise<void> =>
    api.post<void>(`lead/${leadId}/accept-underwrite-valuation`);

const leadService = {
    acceptUnderwriteValuation,
    assign,
    exportSearch,
    favourite,
    getAdditionalVehicleDetails,
    getForValuation,
    getG3AuctionLeads,
    getG3AuctionLeadStats,
    getLeadCountsByStatus,
    getLeadFilters,
    getLeadStats,
    getLeadView,
    getMetric,
    getOtherValuationsForLead,
    getTasks,
    getTaskStats,
    retailSearch,
    search,
    transferLeadsToCbc,
    transferToCbc,
    update,
    updateForValuation,
};

export default leadService;
