import { CreateValuationModel } from "Types/CreateValuationModel";
import { CreateValuationWithUserModel } from "Types/CreateValuationWithUserModel";
import { DataTableResult } from "Types/DataTableResult";
import { TempVehicleModel } from "Types/TempVehicleModel";
import { CreateManualValuationModel } from "Types/Valuation/CreateManualValuationModel";
import { CreateUnderwriteValuationModel } from "Types/Valuation/CreateUnderwriteValuationModel";
import { RepeatValuationModel } from "Types/Valuation/RepeatValuationModel";
import { RepeatValuationSearchModel } from "Types/Valuation/RepeatValuationSearchModel";
import { UnderwriteValuationModel } from "Types/Valuation/UnderwriteValuationModel";
import { ValuationRequestModel } from "Types/Valuation/ValuationRequestModel";
import api from "./Api";

const createManualValuation = async (model: CreateManualValuationModel) => {
    return api.post<TempVehicleModel>(`valuation/manual`, model);
};

const getRepeatValuations = async (vrm: string, leadId?: number): Promise<DataTableResult<RepeatValuationModel>> => {
    const query: RepeatValuationSearchModel = {
        vrm,
        excludeLeadId: leadId,
    };

    return api.get<DataTableResult<RepeatValuationModel>>(`valuation/repeats`, query);
};

const getValuation = async (model: CreateValuationModel) => {
    return api.post<TempVehicleModel>(`valuation`, model);
};

const getValuationForOrg = async (model: CreateValuationWithUserModel): Promise<TempVehicleModel> => {
    return api.post<TempVehicleModel>(`valuation/organisation`, model);
};

const revalue = async (leadId: number) => {
    return api.post<TempVehicleModel>(`valuation/revalue/${leadId}`);
};

const importPythonFile = async (file: File) => {
    return api.postFileDownloadFile(`valuation/python-file`, file, false);
};

const requestManualValuation = async (leadId: number) => {
    return api.post(`valuation/manual/request/${leadId}`);
};

const getManualValuation = async (leadId: number): Promise<ValuationRequestModel> => {
    return api.get(`valuation/manual/${leadId}`);
};

const underwrite = async (model: CreateUnderwriteValuationModel): Promise<UnderwriteValuationModel> =>
    api.post<UnderwriteValuationModel>(`valuation/underwrite`, model);

const ValuationService = {
    createManualValuation,
    getManualValuation,
    getRepeatValuations,
    getValuation,
    getValuationForOrg,
    importPythonFile,
    requestManualValuation,
    revalue,
    underwrite,
};

export default ValuationService;
