import React, { useCallback } from "react";
import { useToasts } from "react-toast-notifications";

export default function useDmwToasts() {
    const { addToast } = useToasts();

    const errorToast = useCallback(
        (content: any) =>
            addToast(content?.message ?? content?.title ?? content, {
                autoDismiss: false,
                appearance: "error",
            }),
        [addToast]
    );

    const successToast = useCallback(
        (content: React.ReactNode) =>
            addToast(content, {
                autoDismiss: true,
                appearance: "success",
            }),
        [addToast]
    );

    return {
        errorToast,
        successToast,
    };
}
