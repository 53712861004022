import { DateTime } from "luxon";
import { ResizeObserver as ResizeObserverPolyfill } from "@juggle/resize-observer";

export const dateFormat = "dd/MM/yyyy";
export const timeFormat = `HH:mm`;
export const dateTimeFormat = `${dateFormat} ${timeFormat}`;

export const formatToCurrency = (val: number, dp?: number): string => {
    const currencyFormatter = new Intl.NumberFormat("en-GB", {
        style: "currency",
        currency: "GBP",
        maximumFractionDigits: dp ?? 0,
        minimumFractionDigits: dp ?? 0,
    });

    return currencyFormatter.format(val);
};

export const formatToDateTimeString = (val: string): string => {
    if (!val) {
        return "N/A";
    }
    const formatter = new Intl.DateTimeFormat("en-GB", {
        dateStyle: "short",
        timeStyle: "short",
    });

    const date = new Date(val);
    return formatter.format(date);
};

export const isEmpty = (obj: any): boolean => {
    return !obj || !Object.keys(obj).length;
};

export const isNullOrWhiteSpace = (s: string): boolean => (!s ? true : !!String(s).match(/^ *$/));

export const padNumString = (s: string) => s.padStart(2, "0");

export const parseNullableDate = (date: Date | undefined): Date | undefined => (date ? new Date(date) : date);

export const nameofFactory =
    <T>() =>
    (name: keyof T) =>
        name;

export const nameof = <T>(name: keyof T) => name;

export const isOver18 = (birthDate: string) => {
    const now = DateTime.now();
    const birthDateObject = DateTime.fromISO(birthDate);
    const comparison = now.diff(birthDateObject, ["years"]);

    return comparison.years > 18 ? true : false;
};

export const isValidPhoneNumber = (number: string) => {
    const regex = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    if (number.startsWith("0")) {
        number = number.replace(number[0], "+44");
    }
    return regex.test(number);
};

export const isValidEmailAddress = (email: string) => {
    const regex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;

    return regex.test(email);
};

export const isValidUrl = (value: string) => {
    let url: URL;

    try {
        url = new URL(value);
    } catch (_) {
        return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
};

export const urltoFile = (url, filename, mimeType) => {
    return fetch(url)
        .then(function (res) {
            return res.arrayBuffer();
        })
        .then(function (buf) {
            return new File([buf], filename, { type: mimeType });
        });
};

export const initializeResizeObserver = () => {
    if (typeof window !== "undefined") {
        window.ResizeObserver = window.ResizeObserver || ResizeObserverPolyfill;
    }
};
