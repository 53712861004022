import {
    faBan,
    faBell,
    faBook,
    faCar,
    faCog,
    faFileContract,
    faGavel,
    faHandshake,
    faHome,
    faPeopleRoof,
    faTasks,
    IconDefinition,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NotificationsLink from "Components/Notifications/NotificationsLink";
import { PermissionGroups } from "Constants/PermissionGroups";
import useRoles from "Hooks/UseRoles";
import { UserSettingsContext } from "Providers/UserSettings/UserSettingsProvider";
import authService from "Services/AuthService";
import OrganisationService from "Services/Organisation/OrganisationService";
import { ROLES } from "Types/Enums/Roles";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Link, NavLink, To, useNavigate } from "react-router-dom";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import "./Sidebar.css";

interface SidebarNavLink {
    element?: JSX.Element;
    icon?: IconDefinition;
    label: string;
    spacer?: boolean;
    to?: To;
}

interface SidebarProps {
    children: JSX.Element;
    username: string;
}

export const Sidebar = ({ children, username }: SidebarProps): React.ReactElement => {
    const navigate = useNavigate();

    const { state, dispatch } = useContext(UserSettingsContext);
    const { organisation, logoLoadedHash } = state;

    const [sidebarOpen, toggleSidebar] = useState(false);

    const handleLogout = () => {
        authService.logout().then(() => {
            navigate("/login");
        });
    };

    const { hasRoles, roles } = useRoles();

    const navLinks: SidebarNavLink[] = useMemo(() => {
        const isOrganisationUser = PermissionGroups.isOrganisationUser(roles);
        const isPermissionGroup1 = PermissionGroups.isPermissionGroup1(roles);
        const isPermissionGroup2 = PermissionGroups.isPermissionGroup2(roles);
        const isPermissionGroup3 = PermissionGroups.isPermissionGroup3(roles);

        const links: SidebarNavLink[] = [];

        if (isOrganisationUser) {
            links.push({
                icon: faHome,
                label: "Dashboard",
                to: "/",
            });
        }

        if (isOrganisationUser) {
            links.push({
                element: <NotificationsLink />,
                label: "Notifications",
            });
        }

        if (isOrganisationUser) {
            links.push({
                icon: faBook,
                label: "Diary",
                to: "/diary",
            });
        }

        if (isOrganisationUser) {
            links.push({
                icon: faCar,
                label: "Valuations",
                to: "/leads",
            });
        }

        if (isPermissionGroup1) {
            links.push({
                icon: faTasks,
                label: "Tasks",
                to: "/tasks",
            });
        }

        if (isPermissionGroup2) {
            links.push({
                icon: faHandshake,
                label: "Deals",
                to: "/deals",
            });
        }

        if (isPermissionGroup2) {
            links.push({
                icon: faGavel,
                label: "G3 Auctions",
                to: "/g3-auctions",
            });
        }

        if (roles.includes(ROLES.UNDERWRITE_VALUER)) {
            links.push({
                icon: faFileContract,
                label: "Underwrite Value",
                to: "/underwrite",
            });
        }

        if (isOrganisationUser) {
            links.push({
                label: "spacer-1",
                spacer: true,
            });
        }

        if (isPermissionGroup2) {
            links.push({
                icon: faBell,
                label: "Retail Profiles",
                to: "/retail",
            });
        }

        if (isPermissionGroup2) {
            links.push({
                icon: faCog,
                label: "Tailored Refactoring",
                to: "/factors",
            });
        }

        if (isPermissionGroup3) {
            links.push({
                icon: faBan,
                label: "Auto-Decline Rules",
                to: "/auto-decline-rules",
            });
        }

        if (isPermissionGroup3) {
            links.push({
                icon: faCar,
                label: "Car Buying Crew",
                to: "/cbc",
            });
        }

        if (isPermissionGroup3) {
            links.push({
                icon: faPeopleRoof,
                label: "Child Organisations",
                to: "/child-organisations",
            });
        }

        return links;
    }, [roles]);

    const getOrganisation = useCallback(async () => {
        try {
            const res = await OrganisationService.getUserOrganisationSettings();
            dispatch({ type: "SET_ORGANISATION", organisation: res });
        } catch {
            // Do nothing
        }
    }, [dispatch]);

    useEffect(() => {
        if (!organisation) {
            getOrganisation();
        }
    }, [getOrganisation, organisation]);

    return (
        <>
            <aside
                className={sidebarOpen ? "left-sidebar text-center pt-4" : "left-sidebar text-center pt-4 open-sidebar"}
            >
                <div
                    onClick={() => toggleSidebar(!sidebarOpen)}
                    className={sidebarOpen ? "sidebar-chevron" : "sidebar-chevron sidebar-rotate"}
                >
                    <i className="fas fa-chevron-right chevron"></i>
                </div>
                <div className="scroll-sidebar">
                    {organisation && organisation.logoStorageUrl && (
                        <Link to="/">
                            <img
                                alt={"Organisation logo"}
                                key={logoLoadedHash}
                                src={`${organisation.logoStorageUrl}?${logoLoadedHash}`}
                                className="sidebar-logo mb-2"
                            />
                        </Link>
                    )}

                    <nav className="sidebar-nav mt-2">
                        <ul className={"sidebar-list"}>
                            {navLinks.map(nl => (
                                <li className={"sidebar-item"} key={nl.label}>
                                    {nl.element ? (
                                        nl.element
                                    ) : nl.spacer ? (
                                        <hr className={"sidebar-spacer"} />
                                    ) : (
                                        <NavLink className={"sidebar-link"} to={nl.to}>
                                            <FontAwesomeIcon icon={nl.icon} />

                                            <span className={"ms-3"}>{nl.label}</span>
                                        </NavLink>
                                    )}
                                </li>
                            ))}
                        </ul>
                    </nav>

                    <div className="sidebar-dropdown">
                        <nav className="mt-2">
                            <ul className={"sidebar-list"}>
                                <li className="sidebar-item sidebar-dropdown-item">
                                    <UncontrolledDropdown className="sidebar-dropdown-link">
                                        <DropdownToggle tag="span" caret>
                                            <i className="fa fa-user"></i>
                                            <span className="ms-3">{username}</span>
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem>
                                                <NavLink to="/account">My Account</NavLink>
                                            </DropdownItem>
                                            {hasRoles([ROLES.SYSTEM_ADMINISTRATOR]) && (
                                                <>
                                                    <DropdownItem>
                                                        <NavLink to="/organisations">Organisations</NavLink>
                                                    </DropdownItem>
                                                </>
                                            )}
                                            {hasRoles([ROLES.ORGANISATION_MODERATOR, ROLES.SYSTEM_ADMINISTRATOR]) && (
                                                <>
                                                    <DropdownItem>
                                                        <NavLink to="/users">Organisation Users</NavLink>
                                                    </DropdownItem>
                                                    <DropdownItem>
                                                        <NavLink to="/branches">Branches</NavLink>
                                                    </DropdownItem>
                                                    <DropdownItem>
                                                        <NavLink to={"/developers"}>Developers</NavLink>
                                                    </DropdownItem>
                                                </>
                                            )}
                                            <DropdownItem>
                                                <a
                                                    href="https://help.g3signal.co.uk/en"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    Help & Support
                                                </a>
                                            </DropdownItem>
                                            <DropdownItem onClick={handleLogout}>Logout</DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </li>
                            </ul>
                        </nav>

                        <img
                            src="https://media.g3r.co.uk/signal/powered-by-signal.png"
                            alt="Powered by Signal"
                            className="w-75 mb-3"
                        />
                        <br />
                    </div>
                </div>
            </aside>

            <div className="page-wrapper p-3">{children}</div>
        </>
    );
};

export default Sidebar;
