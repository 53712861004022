import { DateTime } from "luxon";
import api from "Services/Api";
import { AutoDeclineRuleModel } from "Types/AutoDeclineRule/AutoDeclineRuleModel";
import { DataTableResult } from "Types/DataTableResult";
import { BranchModel } from "Types/Organisation/Branch/BranchModel";
import { BranchSearchModel } from "Types/Organisation/Branch/BranchSearchModel";
import { CreateBranchModel } from "Types/Organisation/Branch/CreateBranchModel";
import { CreateChildOrganisationModel } from "Types/Organisation/CreateChildOrganisationModel";
import { CreateOrganisationModel } from "Types/Organisation/CreateOrganisationModel";
import { OrganisationBrandingModel } from "Types/Organisation/OrganisationBrandingModel";
import { OrganisationModel } from "Types/Organisation/OrganisationModel";
import { OrganisationSearchModel } from "Types/Organisation/OrganisationSearchModel";
import { OrganisationWithUserCountModel } from "Types/Organisation/OrganisationWithUserCountModel";
import { OrgStatsReturnModel, StatDates } from "Types/Organisation/OrgStats";
import { UpdateOrganisationBoostValuationModel } from "Types/Organisation/UpdateOrganisationBoostValuationModel";
import { UpdateOrganisationModel } from "Types/Organisation/UpdateOrganisationModel";
import { UpdateOrganisationValuationRangeModel } from "Types/Organisation/UpdateOrganisationValuationRangeModel";
import { ValuationConfigModel } from "Types/Organisation/ValuationConfigModel";
import { PagingModel } from "Types/PagingModel";

const createBranch = async (model: CreateBranchModel): Promise<BranchModel> => {
    return api.post(`organisation/branch`, model);
};

// only for use by G3 admins
const createBranchOnOrganisation = async (model: CreateBranchModel): Promise<BranchModel> => {
    return api.post(`organisation/branch`, model);
};

const createChildOrganisation = async (model: CreateChildOrganisationModel): Promise<OrganisationModel> => {
    return api.post<OrganisationModel>(`organisation/child`, model);
};

const createOrganisation = async (model: CreateOrganisationModel): Promise<OrganisationModel> => {
    return api.post(`organisation`, model);
};

const _delete = async (organisationId: number): Promise<boolean> => {
    return api._delete(`organisation/${organisationId}`);
};

const getAutoDeclineRules = async (
    organisationId: number,
    model: PagingModel
): Promise<DataTableResult<AutoDeclineRuleModel>> => {
    return api.get<DataTableResult<AutoDeclineRuleModel>>(`organisation/${organisationId}/auto-decline-rules`, model);
};

const getBranding = async (organisationId: number): Promise<OrganisationBrandingModel> =>
    api.get<OrganisationBrandingModel>(`organisation/${organisationId}/branding`);

const getOpenBookings = async (date: string): Promise<Map<string, string>> => {
    const formattedDate = DateTime.fromISO(date).toFormat("yyyy-MM-dd");
    return api.get<Map<string, string>>(`organisation/open-booking/${formattedDate}`);
};

const getOrganisation = async (organisationId: number): Promise<OrganisationModel> => {
    return api.get(`organisation/${organisationId}`);
};

const getUserOrganisationSettings = async (): Promise<OrganisationModel> => {
    return api.get("organisation/settings");
};

const searchBranches = async (model: BranchSearchModel): Promise<BranchModel[]> => {
    return api.get<BranchModel[]>(`organisation/branch`, model);
};

// only for use by G3 admins
const searchBranchesOnOrganisation = async (model: BranchSearchModel): Promise<BranchModel[]> => {
    return api.get<BranchModel[]>(`organisation/branch`, model);
};

const searchChildOrganisations = async (model: OrganisationSearchModel): Promise<OrganisationWithUserCountModel[]> => {
    return api.get<OrganisationWithUserCountModel[]>(`organisation/child`, model);
};

const searchOrganisations = async (model: OrganisationSearchModel): Promise<OrganisationWithUserCountModel[]> => {
    return api.get<OrganisationWithUserCountModel[]>(`organisation`, model);
};

const update = (organisationId: number, model: UpdateOrganisationModel): Promise<OrganisationModel> => {
    return api.put<OrganisationModel>(`organisation/${organisationId}`, model);
};

const updateBoostValuation = (model: UpdateOrganisationBoostValuationModel): Promise<OrganisationModel> => {
    return api.put<OrganisationModel>(`organisation/boost-valuation`, model);
};

const updateValuationRange = (model: UpdateOrganisationValuationRangeModel): Promise<OrganisationModel> => {
    return api.put<OrganisationModel>(`organisation/valuation-range`, model);
};

const uploadLogo = async (organisationId: number, file: File): Promise<OrganisationModel> => {
    return api.postFile(`organisation/${organisationId}/logo`, file);
};

const valuationConfig = async (): Promise<ValuationConfigModel> => {
    return api.get<ValuationConfigModel>(`organisation/valuation-config`);
};

const stats = async (statDates: StatDates): Promise<OrgStatsReturnModel> => {
    return api.get(`organisation/stats`, { statDates: statDates });
};

const OrganisationService = {
    createBranch,
    createBranchOnOrganisation,
    createChildOrganisation,
    createOrganisation,
    _delete,
    getAutoDeclineRules,
    getBranding,
    getOrganisation,
    getOpenBookings,
    getUserOrganisationSettings,
    searchBranches,
    searchBranchesOnOrganisation,
    searchChildOrganisations,
    searchOrganisations,
    stats,
    update,
    updateBoostValuation,
    updateValuationRange,
    uploadLogo,
    valuationConfig,
};

export default OrganisationService;
