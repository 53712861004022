import { faBook, faCar, faHome } from "@fortawesome/free-solid-svg-icons";
import NotificationsLink from "Components/Notifications/NotificationsLink";
import { PermissionGroups } from "Constants/PermissionGroups";
import useRoles from "Hooks/UseRoles";
import { useMemo } from "react";
import { SidebarNavLink } from "../Sidebar";
import SidebarNavItem from "../SidebarNavItem/SidebarNavItem";

const OrgUserLinks = () => {
    const { roles } = useRoles();

    const navLinks: SidebarNavLink[] = useMemo(() => {
        const isOrganisationUser = PermissionGroups.isOrganisationUser(roles);

        if (!isOrganisationUser) {
            return [];
        }

        const links: SidebarNavLink[] = [];

        links.push({
            icon: faHome,
            label: "Dashboard",
            to: "/",
        });

        links.push({
            element: <NotificationsLink />,
            label: "Notifications",
        });

        links.push({
            icon: faBook,
            label: "Diary",
            to: "/diary",
        });

        links.push({
            icon: faCar,
            label: "Valuations",
            to: "/leads",
        });

        return links;
    }, [roles]);

    return (
        <>
            {navLinks.map(nl => (
                <SidebarNavItem navLink={nl} key={nl.label} />
            ))}
        </>
    );
};

export default OrgUserLinks;
