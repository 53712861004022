import { DataTableResult } from "Types/DataTableResult";
import { NotificationModel } from "Types/Notification/NotificationModel";
import { UnacknowledgedNotificationsModel } from "Types/Notification/UnacknowledgedNotificationsModel";
import { PagingModel } from "Types/PagingModel";
import api from "./Api";

const acknowledge = (notificationId: number): Promise<void> => {
    return api.post<void>(`notification/${notificationId}/acknowledge`);
};

const acknowledgeAll = (): Promise<void> => {
    return api.post<void>("notification/acknowledge-all");
};

const get = (model: PagingModel): Promise<DataTableResult<NotificationModel>> => {
    return api.get<DataTableResult<NotificationModel>>("notification", model);
};

const getUnacknowledgedCount = (): Promise<UnacknowledgedNotificationsModel> => {
    return api.get<UnacknowledgedNotificationsModel>("notification/unacknowledged");
};

const notificationService = {
    acknowledge,
    acknowledgeAll,
    get,
    getUnacknowledgedCount,
};

export default notificationService;
