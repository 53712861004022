import useDmwToasts from "Hooks/UseDmwToasts";
import LeadNoteService from "Services/LeadNoteService";
import { LeadNoteModel } from "Types/LeadNote/LeadNoteModel";
import React, { useCallback, useEffect, useState } from "react";
import ContactActivityCard from "./ContactActivityCard";

export const ActivityHistory = ({ leadId, handleReload }: { leadId: number; handleReload(val: number): void }) => {
    const { errorToast } = useDmwToasts();
    const [data, setData] = useState<LeadNoteModel[]>([]);

    const loadData = useCallback(() => {
        LeadNoteService.getNotesByLead(leadId).then(setData).catch(errorToast);
    }, [errorToast, leadId]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    const reload = useCallback(
        (val: number) => {
            loadData();
            handleReload(val), [];
        },
        [handleReload, loadData]
    );

    return (
        <>
            {data && data.length > 0 ? (
                data.map((activity: LeadNoteModel) => (
                    <React.Fragment key={activity.leadNoteId}>
                        <ContactActivityCard activity={activity} reload={reload} />
                    </React.Fragment>
                ))
            ) : (
                <span className="text-center mt-3">[No Activities Found]</span>
            )}
        </>
    );
};
