import { faFileContract } from "@fortawesome/free-solid-svg-icons";
import useRoles from "Hooks/UseRoles";
import { useMemo } from "react";
import { ROLES } from "Types/Enums/Roles";
import { SidebarNavLink } from "../Sidebar";
import SidebarNavItem from "../SidebarNavItem/SidebarNavItem";
import AutoDeclineRulesLink from "./AutoDeclineRulesLink";
import CarBuyingCrewLink from "./CarBuyingCrewLink";
import ChildOrgsLink from "./ChildOrgsLink";
import GenerateAiLink from "./GenerateAiLink";
import NoValuationAdminLinks from "./NoValuationAdminLinks";
import OrgUserLinks from "./OrgUserLinks";
import RetailProfilesLink from "./RetailProfilesLink";
import TailoredFactoringLink from "./TailoredFactoringLink";

const SidebarLinks = () => {
    const { roles } = useRoles();

    const navLinks: SidebarNavLink[] = useMemo(() => {
        const links: SidebarNavLink[] = [];

        if (roles.includes(ROLES.UNDERWRITE_VALUER)) {
            links.push({
                icon: faFileContract,
                label: "Underwrite Value",
                to: "/underwrite",
            });
        }

        return links;
    }, [roles]);

    return (
        <>
            <nav className="sidebar-nav mt-2">
                <ul className={"sidebar-list"}>
                    <OrgUserLinks />
                    <NoValuationAdminLinks />
                    <GenerateAiLink />
                    <RetailProfilesLink />
                    <TailoredFactoringLink />
                    <AutoDeclineRulesLink />
                    <CarBuyingCrewLink />
                    <ChildOrgsLink />
                    {navLinks.map(nl => (
                        <SidebarNavItem navLink={nl} key={nl.label} />
                    ))}
                </ul>
            </nav>
        </>
    );
};
export default SidebarLinks;
