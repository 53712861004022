import { G3Dropdown, G3Input, G3TextArea, IconButton } from "@g3r-developers/g3-common";
import useDmwToasts from "Hooks/UseDmwToasts";
import dealService from "Services/DealService";
import dropdownService from "Services/DropdownService";
import leadNoteService from "Services/LeadNoteService";
import { CreateDealModel } from "Types/Deal/CreateDealModel";
import { ContactActivityOutcome } from "Types/Enums/ContactActivityOutcome";
import { ContactActivityType } from "Types/Enums/ContactActivityType";
import { DealStatus } from "Types/Enums/DealStatus";
import { LOOKUP_TYPES } from "Types/Enums/LookupTypes";
import { CreateLeadNoteFollowUpModel } from "Types/LeadNote/CreateLeadNoteFollowUpModel";
import { UpdateLeadNoteModel } from "Types/LeadNote/UpdateLeadNoteModel";
import { LeadViewModel } from "Types/lead/LeadViewModel";
import { DateTime } from "luxon";
import { useCallback, useMemo, useState } from "react";
import { Accordion, AccordionBody, AccordionHeader, AccordionItem, Col, Row } from "reactstrap";
import { DialerExpandableRowProps } from "./DialerExpandableRow";
import { DialerSmsButton } from "./DialerSmsButton";

export interface DialerAccordionProps extends DialerExpandableRowProps {
    lead: LeadViewModel;
}

export enum DialerAccordionState {
    StartCall = "StartCall",
    Outcome = "Outcome",
    NextSteps = "NextSteps",
}

export const DialerAccordion = ({ data, lead }: DialerAccordionProps) => {
    const { successToast, errorToast } = useDmwToasts();
    const [open, setOpen] = useState<string>(DialerAccordionState.StartCall.toString());
    const [timeStarted, setTimeStarted] = useState<DateTime | undefined>(undefined);

    const [outcome, setOutcome] = useState<number | undefined>(undefined);
    const [notes, setNotes] = useState("");
    const [rejectionReason, setRejectionReason] = useState<number | undefined>(undefined);
    const [dealOffer, setDealOffer] = useState<number | undefined>(undefined);
    const [followUpDate, setFollowUpDate] = useState<DateTime | undefined>(undefined);
    const [saving, setSaving] = useState(false);

    const handleToggle = useCallback((newId: string) => {
        setOpen(newId);
    }, []);

    const handleStartCall = useCallback(() => {
        setTimeStarted(DateTime.now());
        setOpen(DialerAccordionState.Outcome);
    }, []);

    const startCallDisabled = useMemo(() => {
        return timeStarted !== undefined;
    }, [timeStarted]);

    const showFollowUpOptions = useMemo(() => {
        return outcome !== undefined && outcome !== ContactActivityOutcome.Rejected;
    }, [outcome]);

    const saveDisabled = useMemo(() => {
        if (outcome === undefined) {
            return true;
        }

        if (outcome === ContactActivityOutcome.DealOffered && dealOffer === undefined) {
            return true;
        }

        if (outcome === ContactActivityOutcome.Rejected && rejectionReason === undefined) {
            return true;
        }

        if (notes === "") {
            return true;
        }
        if (saving) {
            return true;
        }

        return false;
    }, [dealOffer, notes, outcome, rejectionReason, saving]);

    const handleNotesChanged = useCallback((newVal: string) => {
        setNotes(newVal);
    }, []);

    const handleRejectionReasonChanged = useCallback((newVal: number) => {
        setRejectionReason(newVal);
    }, []);

    const followUpDays = useCallback(() => {
        return Promise.resolve([
            {
                label: "Follow-Up in 1 Hour",
                value: 1,
            },
            {
                label: "Follow-Up in 2 Hours",
                value: 2,
            },
            {
                label: "Follow-Up in 3 Hours",
                value: 3,
            },
            {
                label: "Follow-Up in 6 Hours",
                value: 6,
            },
            {
                label: "Follow-Up in 1 Day",
                value: 24,
            },
            {
                label: "Follow-Up in 3 Days",
                value: 24 * 3,
            },
            {
                label: "Follow-Up in 5 Days",
                value: 24 * 5,
            },
            {
                label: "Follow-Up in 7 Days",
                value: 24 * 7,
            },
            {
                label: "Follow-Up in 14 Days",
                value: 24 * 14,
            },
        ]);
    }, []);

    const handleFollowUpChanged = useCallback(
        (hoursToAdd: number) => {
            if (hoursToAdd >= 24) {
                setFollowUpDate(timeStarted.startOf("day").plus({ hours: hoursToAdd }));
            } else {
                setFollowUpDate(timeStarted.startOf("minute").plus({ hours: hoursToAdd }));
            }
        },
        [timeStarted]
    );

    const handleUpdateLead = useCallback(async () => {
        const model = {
            text: notes,
            actionedDate: timeStarted.toISO(),
            activityOutcomeId: outcome,
        } as UpdateLeadNoteModel;

        if (followUpDate) {
            model.followUp = {
                activityDate: followUpDate.toISO(),
                activityTypeId: ContactActivityType.Call,
                text: "Follow-Up",
                assignedUserId: lead.user?.id,
            } as CreateLeadNoteFollowUpModel;
        }
        if (rejectionReason) {
            model.rejectedReasonId = rejectionReason;
        }
        setSaving(true);
        try {
            await leadNoteService.update(data.currentLeadNoteId, model);

            if (outcome === ContactActivityOutcome.DealOffered) {
                if (lead.deal !== null) {
                    await dealService.update(lead.deal.dealId, {
                        price: dealOffer,
                        dealStatusId: DealStatus.Offered,
                    });
                } else {
                    await dealService.create({
                        leadId: data.leadId,
                        price: dealOffer,
                        dealStatusId: DealStatus.Offered,
                    } as CreateDealModel);
                }
            }
            window.location.reload();
        } catch (e) {
            errorToast(e);
        } finally {
            setSaving(false);
        }
    }, [
        data.currentLeadNoteId,
        data.leadId,
        dealOffer,
        errorToast,
        followUpDate,
        lead.deal,
        lead.user?.id,
        notes,
        outcome,
        rejectionReason,
        timeStarted,
    ]);

    const handleSave = useCallback(() => {
        if (data.currentLeadNoteId) {
            handleUpdateLead();
            return;
        }
    }, [data.currentLeadNoteId, handleUpdateLead]);

    return (
        <Accordion open={open} toggle={handleToggle}>
            <AccordionItem>
                <AccordionHeader targetId={DialerAccordionState.StartCall}>Start Call</AccordionHeader>
                <AccordionBody accordionId={DialerAccordionState.StartCall}>
                    <Row className="gy-2">
                        <DialerSmsButton leadId={data.leadId} smsSent={data.smsSent} />
                        <h2 className="text-center ">
                            Number to Dial: <strong>{lead.mobile}</strong>
                        </h2>
                        <IconButton block text="Start Call" onClick={handleStartCall} disabled={startCallDisabled} />
                    </Row>
                </AccordionBody>
            </AccordionItem>
            <AccordionItem>
                <AccordionHeader targetId={DialerAccordionState.Outcome}>Call Outcome</AccordionHeader>
                <AccordionBody accordionId={DialerAccordionState.Outcome}>
                    <Row className="gy-3">
                        <Col xs={4}>
                            <IconButton
                                onClick={() => setOutcome(ContactActivityOutcome.Answered)}
                                block
                                outline={outcome !== ContactActivityOutcome.Answered}
                                variant={"success"}
                                text="Answered - Follow Up"
                            />
                        </Col>
                        <Col xs={4}>
                            <IconButton
                                onClick={() => setOutcome(ContactActivityOutcome.DealOffered)}
                                outline={outcome !== ContactActivityOutcome.DealOffered}
                                block
                                variant="success"
                                text="Deal Offered"
                            />
                        </Col>
                        <Col xs={4}>
                            <IconButton
                                onClick={() => setOutcome(ContactActivityOutcome.DealAccepted)}
                                outline={outcome !== ContactActivityOutcome.DealAccepted}
                                block
                                variant="success"
                                text="Deal Accepted"
                            />
                        </Col>
                        <Col xs={4}>
                            <IconButton
                                onClick={() => setOutcome(ContactActivityOutcome.RequestedCallback)}
                                outline={outcome !== ContactActivityOutcome.RequestedCallback}
                                block
                                variant="orange"
                                text="Requested Callback"
                            />
                        </Col>
                        <Col xs={4}>
                            <IconButton
                                onClick={() => setOutcome(ContactActivityOutcome.NoAnswer)}
                                outline={outcome !== ContactActivityOutcome.NoAnswer}
                                block
                                variant="orange"
                                text="No Answer"
                            />
                        </Col>
                        <Col xs={4}>
                            <IconButton
                                onClick={() => setOutcome(ContactActivityOutcome.LeftMessage)}
                                outline={outcome !== ContactActivityOutcome.LeftMessage}
                                block
                                variant="orange"
                                text="Left Message"
                            />
                        </Col>
                        <Col xs={4}>
                            <IconButton
                                onClick={() => setOutcome(ContactActivityOutcome.Rejected)}
                                outline={outcome !== ContactActivityOutcome.Rejected}
                                block
                                variant="danger"
                                text="Deal Rejected"
                            />
                        </Col>
                    </Row>

                    <Row className="mt-3">
                        <Col xs={12}>
                            <G3TextArea label="Call Notes" value={notes} onChange={handleNotesChanged} />
                        </Col>
                        <Col xs={12}>
                            <strong>Next Steps</strong>

                            {outcome === ContactActivityOutcome.DealOffered && (
                                <Row>
                                    <Col xs={12}>
                                        <G3Input
                                            label="Deal Value"
                                            value={dealOffer}
                                            type="number"
                                            onChange={(newVal: number) => setDealOffer(newVal)}
                                        />
                                    </Col>
                                </Row>
                            )}

                            {outcome === ContactActivityOutcome.Rejected && (
                                <Row>
                                    <Col xs={12}>
                                        <G3Dropdown
                                            label="Rejection Reason"
                                            loadOptions={() =>
                                                dropdownService.lookups(LOOKUP_TYPES.DealRejectionReason)
                                            }
                                            onChange={handleRejectionReasonChanged}
                                            defaultOption={rejectionReason}
                                        />
                                    </Col>
                                </Row>
                            )}

                            {showFollowUpOptions && (
                                <Row>
                                    <Col xs={12}>
                                        <G3Dropdown
                                            label="Follow-Up"
                                            loadOptions={followUpDays}
                                            onChange={handleFollowUpChanged}
                                        />
                                    </Col>
                                </Row>
                            )}

                            <IconButton
                                className="mt-3"
                                block
                                text="Save"
                                disabled={saveDisabled}
                                onClick={handleSave}
                            />
                        </Col>
                    </Row>
                </AccordionBody>
            </AccordionItem>
        </Accordion>
    );
};
