import { faAt, faComputer, faNoteSticky, faPhone, faSms, faUsers } from "@fortawesome/free-solid-svg-icons";
import { ContactActivityType } from "Types/Enums/ContactActivityType";

export const activityTypeIdToFaIcon = (id: number) => {
    switch (id) {
        case ContactActivityType.Call: {
            return faPhone;
        }
        case ContactActivityType.Email: {
            return faAt;
        }
        case ContactActivityType.PhysicalMeeting: {
            return faUsers;
        }
        case ContactActivityType.SMS: {
            return faSms;
        }
        case ContactActivityType.SystemGenerated: {
            return faComputer;
        }
        case ContactActivityType.Note: {
            return faNoteSticky;
        }
    }
};
