import { IconButton, showconfirmmodal } from "@g3r-developers/g3-common";
import useDmwToasts from "Hooks/UseDmwToasts";
import LeadPriorityService from "Services/LeadPriorityService";
import { dateTimeFormat } from "Services/Utils";
import { DateTime } from "luxon";
import { useCallback, useMemo, useState } from "react";

interface DialerSmsButtonProps {
    leadId: number;
    smsSent?: string;
}

export const DialerSmsButton = ({ smsSent, leadId }: DialerSmsButtonProps) => {
    const { successToast, errorToast } = useDmwToasts();

    const [sending, setSending] = useState(false);

    const handleSendSms = useCallback(async () => {
        const confirm = await showconfirmmodal("Send SMS", "Are you sure you want to send the pre-call SMS?");
        if (!confirm) {
            return;
        }
        setSending(true);

        try {
            // Send SMS
            await LeadPriorityService.sendPreCallSms(leadId);
            successToast("SMS Sent");
        } catch (e) {
            // Show Error
            errorToast(e);
        } finally {
            setSending(false);
        }
    }, [errorToast, leadId, successToast]);

    const tooltip = useMemo(() => {
        if (smsSent === null) {
            return undefined;
        }
        return `SMS Sent: ${DateTime.fromISO(smsSent).toFormat(dateTimeFormat)}`;
    }, [smsSent]);

    const buttonColor = useMemo(() => {
        if (smsSent === null) {
            return "secondary";
        }

        return "success";
    }, [smsSent]);

    return (
        <>
            <IconButton
                tooltip={tooltip}
                disabled={sending}
                block
                variant={buttonColor}
                onClick={handleSendSms}
                text="Send SMS"
            />
        </>
    );
};
