import { faList } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSession } from "Hooks/UseSession";
import dropdownService from "Services/DropdownService";
import DropdownModel from "Types/DropdownModel";
import { useCallback, useEffect, useState } from "react";
import { ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";

interface DiaryUserSelectProps {
    selectedUser: DropdownModel;
    userChanged: (newUser: DropdownModel) => void;
}

export default function DiaryUserSelect({ userChanged, selectedUser }: DiaryUserSelectProps) {
    const { user } = useSession();

    const [users, setUsers] = useState<DropdownModel[]>(undefined);
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggleDropdown = useCallback(() => {
        setDropdownOpen(p => !p);
    }, []);

    const loadUsers = useCallback(async (branchId: number) => {
        const u = await dropdownService.branchesUsers(branchId);
        const everyone = {
            label: "Everyone",
            value: -1,
        } as DropdownModel;
        u.unshift(everyone);
        setUsers(u);
    }, []);

    useEffect(() => {
        if (!user) {
            return;
        }

        loadUsers(user.branchId);
    }, [loadUsers, user]);

    return (
        <>
            {users && (
                <ButtonDropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                    <DropdownToggle caret>
                        <FontAwesomeIcon icon={faList} />
                        &nbsp;
                        {selectedUser?.label}
                    </DropdownToggle>
                    <DropdownMenu>
                        {users.map((u, idx) => (
                            <DropdownItem key={idx} onClick={() => userChanged(u)}>
                                {u.label}
                            </DropdownItem>
                        ))}
                    </DropdownMenu>
                </ButtonDropdown>
            )}
        </>
    );
}
