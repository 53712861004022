import download from "downloadjs";
import { sessionService } from "redux-react-session";
import { DmwAuthReturnModel } from "Types/DmwAuthReturnModel";

export const _delete = async <T>(url: string): Promise<T> => {
    const apiUrl = `${import.meta.env.VITE_API_URL}${url}`;

    const finalApi = new URL(apiUrl);

    const options = await getOptions("DELETE", false);

    return fetch(finalApi.toString(), options).then(res => handleResponse<T>(res));
};

export const get = async <T>(url: string, data?: any, vampUrl = false): Promise<T> => {
    let apiUrl = `${import.meta.env.VITE_API_URL}${url}`;

    if (vampUrl) {
        apiUrl = `${import.meta.env.VITE_VAMP_API_URL}${url}`;
    }

    let finalApi = new URL(apiUrl);

    if (data) {
        finalApi = applyQueryParams(finalApi, data);
    }

    const options = await getOptions("GET", false, data);

    return fetch(finalApi.toString(), options).then(res => handleResponse<T>(res));
};

export const patch = async <T>(url: string, data: any, vampUrl = false): Promise<T> => {
    let apiUrl = `${import.meta.env.VITE_API_URL}${url}`;

    if (vampUrl) {
        apiUrl = `${import.meta.env.VITE_VAMP_API_URL}${url}`;
    }

    const finalApi = new URL(apiUrl);

    const options = await getOptions("PATCH", false, data);

    return fetch(finalApi.toString(), options).then(res => handleResponse<T>(res));
};

export const post = async <T>(url: string, data?: any, vampUrl = false): Promise<T> => {
    let apiUrl = `${import.meta.env.VITE_API_URL}${url}`;

    if (vampUrl) {
        apiUrl = `${import.meta.env.VITE_VAMP_API_URL}${url}`;
    }

    const finalApi = new URL(apiUrl);

    const options = await getOptions("POST", false, data);

    return fetch(finalApi.toString(), options).then(res => handleResponse<T>(res));
};

export const put = async <T>(url: string, data: any): Promise<T> => {
    const apiUrl = `${import.meta.env.VITE_API_URL}${url}`;

    const finalApi = new URL(apiUrl);

    const options = await getOptions("PUT", false, data);

    return fetch(finalApi.toString(), options).then(res => handleResponse<T>(res));
};

const buildFormData = (formData, data, parentKey?) => {
    if (data && typeof data === "object" && !(data instanceof Date) && !(data instanceof File)) {
        Object.keys(data).forEach(key => {
            buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
        });
    } else {
        const value = data == null ? "" : data;

        formData.append(parentKey, value);
    }
};

export const postFile = async <T>(url: string, fileToUpload: File, data?: any): Promise<T> => {
    const formData = new FormData();
    if (data) {
        buildFormData(formData, data);
    }
    formData.append("File", fileToUpload);

    const apiUrl = `${import.meta.env.VITE_API_URL}${url}`;

    const finalApi = new URL(apiUrl);

    const options = await getOptions("POST", true, data);

    options.body = formData;

    return fetch(finalApi.toString(), options).then(res => handleResponse<T>(res));
};

async function handleDownloadResponse(response: Response, returnFile?: boolean): Promise<File> {
    let filename = "File";

    const res = response.clone();

    return res
        .text()
        .then((text: string) => {
            if (!res.ok) {
                return Promise.reject(text.toUpperCase());
            }

            return response;
        })
        .then(res => {
            const disposition = res.headers.get("content-disposition");

            if (disposition) {
                filename = disposition.split("filename=")[1].split(";")[0].replaceAll('"', "");
            }

            return res.blob();
        })
        .then(blob => {
            download(blob, filename);

            if (returnFile) {
                return new File([blob], filename, { type: blob.type });
            }
        })
        .catch(reason => {
            return Promise.reject(reason);
        });
}

export const getFileDownload = async (url: string, data?: any, vampUrl = false): Promise<File> => {
    let apiUrl = `${import.meta.env.VITE_API_URL}${url}`;

    if (vampUrl) {
        apiUrl = `${import.meta.env.VITE_VAMP_API_URL}${url}`;
    }

    let finalApi = new URL(apiUrl);

    if (data) {
        finalApi = applyQueryParams(finalApi, data);
    }

    const options = await getOptions("GET", false, data);

    return fetch(finalApi.toString(), options).then(res => handleDownloadResponse(res, true));
};

export const postFileDownloadFile = async (url: string, file: File, returnFile: boolean) => {
    const formData = new FormData();
    formData.append("File", file);
    const apiUrl = `${import.meta.env.VITE_API_URL}${url}`;
    const finalApi = new URL(apiUrl);
    const options = await getOptions("POST", true);
    options.body = formData;

    return fetch(finalApi.toString(), options).then(res => handleDownloadResponse(res, returnFile));
};

function applyQueryParams(url: URL, data: any): URL {
    const urlWithQueryParams = new URL(url.toString());

    Object.keys(data).forEach(key => {
        if (data[key] !== undefined) {
            if (Array.isArray(data[key])) {
                data[key].forEach((_: any) => {
                    urlWithQueryParams.searchParams.append(key, _);
                });
            } else {
                urlWithQueryParams.searchParams.append(key, data[key]);
            }
        }
    });

    return urlWithQueryParams;
}

async function getHeaders(isPostFile: boolean): Promise<Record<string, string>> {
    return sessionService
        .loadSession()
        .then((session: DmwAuthReturnModel) => {
            if (isPostFile) {
                return {
                    Authorization: `Bearer ${session.token}`,
                };
            }
            return {
                "Content-Type": "application/json",
                Authorization: `Bearer ${session.token}`,
            };
        })
        .catch(e => {
            if (isPostFile) {
                return {};
            }
            return {
                "Content-Type": "application/json",
            };
        });
}

async function getOptions(method: string, isPostFile: boolean, data?: any): Promise<RequestInit> {
    const options = {
        headers: await getHeaders(isPostFile),
        method: method,
    } as RequestInit;

    if (hasNoBody(method, data)) {
        return options;
    }

    options.body = isPostFile ? data : JSON.stringify(data);

    return options;
}

async function handleResponse<T>(response: Response): Promise<T> {
    if (!response.ok) {
        const text = await response.text();
        try {
            const error = JSON.parse(text);
            if (error.message === "") {
                return Promise.reject(response.statusText);
            }
            return Promise.reject(error);
        } catch {
            return Promise.reject(text);
        }
    }

    return response.text().then((text: string) => {
        const data = text && JSON.parse(text);

        return data;
    });
}

function hasNoBody(method: string, data: any) {
    return !data || method === "GET";
}

const api = {
    _delete,
    get,
    getFileDownload,
    patch,
    post,
    postFile,
    put,
    postFileDownloadFile,
};

export default api;
