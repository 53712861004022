import { faRobot } from "@fortawesome/free-solid-svg-icons";
import SidebarNavItem from "../SidebarNavItem/SidebarNavItem";

const GenerateAiLink = () => {
    return (
        <>
            <SidebarNavItem
                navLink={{
                    label: "Generate AI",
                    icon: faRobot,
                    to: "/ai",
                }}
            />
        </>
    );
};

export default GenerateAiLink;
