import { AppraisalLeadReturnModel, AppraisalReturnModel } from "Types/Appraisal/AppraisalReturnModel";
import { AppraisalSearchModel } from "Types/Appraisal/AppraisalSearchModel";
import { CreateAppraisalModel } from "Types/Appraisal/CreateAppraisalModel";
import { PhotoAppraisalReturnModel } from "Types/Appraisal/PhotoAppraisalReturnModel";
import { RequestAppraisalModel } from "Types/Appraisal/RequestAppraisalModel";
import { RequestAppraisalPhotosModel } from "Types/Appraisal/RequestAppraisalPhotosModel";
import { SendPhotoAppraisalSmsModel } from "Types/Appraisal/SendPhotoAppraisalSmsModel";
import { DmwAuthReturnModel } from "Types/DmwAuthReturnModel";
import { AppraisalProvider } from "Types/Enums/AppraisalProvider";
import { sessionService } from "redux-react-session";
import api from "./Api";

const checkAppraisal = (appraisalId: number) => {
    return api.post(`appraisal/check/${appraisalId}`);
};

const claimAppraisal = (appraisalId: number): Promise<AppraisalReturnModel> => {
    return api.put(`appraisal/${appraisalId}/claim`, null);
};

const createAppraisal = (model: CreateAppraisalModel): Promise<AppraisalReturnModel> => {
    return api.post(`appraisal`, model);
};

const getAppraisalsForLead = async (
    leadId: number,
    queryParams?: AppraisalSearchModel
): Promise<AppraisalReturnModel[]> => {
    return api.get<AppraisalReturnModel[]>(`appraisal/lead/${leadId}`, queryParams).then(appraisals => {
        appraisals.forEach(a => {
            a.validDamageCostTotal = a.damageLines.map(d => d.repairCost).reduce((ps, _) => ps + _, 0);
        });

        return appraisals;
    });
};

const getAppraisalUrl = (appraisalProviderId: number, sessionKey: string) => {
    if (appraisalProviderId === AppraisalProvider.Pave) {
        return `${import.meta.env.VITE_PAVE_LAUNCH_URL}${sessionKey}`;
    } else {
        return `${import.meta.env.VITE_AVID_URL}${sessionKey}`;
    }
};

const getById = (appraisalId: number): Promise<AppraisalReturnModel> => {
    return api.get(`appraisal/${appraisalId}`);
};

const getByStatusId = (appraisalStatusId: number): Promise<AppraisalLeadReturnModel[]> => {
    return api.get(`appraisal/status-id/${appraisalStatusId}`);
};

const getPhotoAppraisalByLead = (leadId: number): Promise<PhotoAppraisalReturnModel> => {
    return api.get<PhotoAppraisalReturnModel>(`appraisal/photos/lead/${leadId}`);
};

const navigateToAppraisal = (sessionKey: string, leadId: string) => {
    if (sessionKey.startsWith("GTA")) {
        window.localStorage.setItem("pave_key", sessionKey);
        window.localStorage.setItem("lead_id", leadId);
        window.location.href = `${import.meta.env.VITE_PAVE_LAUNCH_URL}${sessionKey}`;
    } else {
        window.localStorage.setItem("pave_key", sessionKey);
        window.localStorage.setItem("lead_id", leadId);
        window.location.href = `${import.meta.env.VITE_AVID_URL}${sessionKey}`;
    }
};

const qrCode = async (appraisalId: number): Promise<string> => {
    let url = `${window.location.protocol}//${window.location.host}/appraisal-portal/${appraisalId}`;

    const session: DmwAuthReturnModel = await sessionService.loadSession();

    url += `?accessToken=${session.token}`;

    return url;
};

const requestAppraisal = async (model: RequestAppraisalModel): Promise<AppraisalReturnModel> => {
    return api.post<AppraisalReturnModel>("appraisal/request", model);
};

const requestPhotosAppraisal = async (model: RequestAppraisalPhotosModel): Promise<PhotoAppraisalReturnModel> => {
    return api.post<PhotoAppraisalReturnModel>("appraisal/request-photos", model);
};

const sendPhotoAppraisalSms = async (appraisalId: number, model: SendPhotoAppraisalSmsModel): Promise<void> =>
    api.post(`appraisal/${appraisalId}/photo-appraisal/send-sms`, model);

const appraisalService = {
    checkAppraisal,
    claimAppraisal,
    createAppraisal,
    getAppraisalsForLead,
    getAppraisalUrl,
    getById,
    getByStatusId,
    getPhotoAppraisalByLead,
    navigateToAppraisal,
    qrCode,
    requestAppraisal,
    requestPhotosAppraisal,
    sendPhotoAppraisalSms,
};

export default appraisalService;
