import { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { sessionService } from "redux-react-session";

export default function RedirectIfAuthedRoute() {
    const [authenticated, setAuthenticated] = useState<boolean>(false);
    const [checked, setChecked] = useState<boolean>(false);

    useEffect(() => {
        sessionService
            .loadSession()
            .then(() => {
                setAuthenticated(true);
                setChecked(true);
            })
            .catch(() => {
                setAuthenticated(false);
                setChecked(true);
            });
    }, []);

    return checked && <>{authenticated ? <Navigate to="/" /> : <Outlet />}</>;
}
