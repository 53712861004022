export enum ContactActivityOutcome {
    NoAnswer = 280,
    LeftMessage = 281,
    Answered = 282,
    Replied = 283,
    Rejected = 284,
    DealOffered = 285,
    ValuationCreated = 286,
    AppraisalRequested = 287,
    AppraisalCompleted = 288,
    DealAccepted = 289,
    RequestedCallback = 334,
}
