import { IconButton } from "@g3r-developers/g3-common";
import { ConditionDescription } from "Components/Underwrite/ConditionDescription";
import { useCallback, useEffect, useRef } from "react";
import { Col, Label, Row } from "reactstrap";
import appraisalService from "Services/AppraisalService";
import ValuationService from "Services/ValuationService";
import { AiGenerate } from "Types/Ai/AiGenerate";
import { CreateAppraisalModel } from "Types/Appraisal/CreateAppraisalModel";
import { AppraisalProvider } from "Types/Enums/AppraisalProvider";
import { CreateUnderwriteValuationModel } from "Types/Valuation/CreateUnderwriteValuationModel";
import { useImmer } from "use-immer";
import GenerateAiAppraisalRow from "../GenerateAiAppraisalRow/GenerateAiAppraisalRow";

const GenerateAiAppraisalsForm = () => {
    const initialized = useRef(false);
    const [rows, setRows] = useImmer<AiGenerate[]>([]);

    const handleRowChanged = useCallback(
        (newModel: AiGenerate) => {
            const uuid = newModel.uuid;

            setRows(draft => {
                const idxToUpdate = draft.findIndex(d => d.uuid == uuid);
                draft[idxToUpdate] = newModel;
            });
        },
        [setRows]
    );

    const handleSendSingleValuation = useCallback(
        async (model: AiGenerate) => {
            try {
                handleRowChanged({ ...model, loading: true });
                const valuation = await ValuationService.underwrite({
                    conditionDescription: ConditionDescription.Excellent,
                    mileage: model.mileage,
                    vrm: model.vrm,
                } as CreateUnderwriteValuationModel);

                if (model.sendSms) {
                    await appraisalService.createAppraisal({
                        appraisalProviderId: AppraisalProvider.Pave,
                        leadId: valuation.leadId,
                        mobile: model.mobile,
                    } as CreateAppraisalModel);
                }

                handleRowChanged({ ...model, loading: false, success: true, valuation: valuation });
            } catch (e) {
                handleRowChanged({ ...model, loading: false, success: false, error: e.message });
            }
        },
        [handleRowChanged]
    );

    const handleSend = useCallback(() => {
        const rowsToSend = rows.filter(r => !r.success);
        for (let i = 0; i < rowsToSend.length; i++) {
            handleSendSingleValuation(rowsToSend[i]);
        }
    }, [handleSendSingleValuation, rows]);

    const handleNewRow = useCallback(() => {
        setRows(draft => {
            draft.push({
                uuid: crypto.randomUUID(),
                sendSms: true,
            } as AiGenerate);
        });
    }, [setRows]);

    const handleRemoveRow = useCallback(
        (uuid: string) => {
            setRows(draft => {
                const idx = draft.findIndex(d => d.uuid === uuid);
                draft.splice(idx, 1);
            });
        },
        [setRows]
    );

    const initialize = useCallback(() => {
        for (let i = 0; i < 5; i++) {
            handleNewRow();
        }
    }, [handleNewRow]);

    useEffect(() => {
        if (initialized.current) {
            return;
        }
        initialize();
        initialized.current = true;
    }, [initialize]);

    return (
        <>
            <Row>
                <Col xs={3}>
                    <Label className="g3-label ps-1">VRM</Label>
                </Col>
                <Col xs={3}>
                    <Label className="g3-label ps-1">Mileage</Label>
                </Col>
                <Col xs={3}>
                    <Label className="g3-label ps-1">Mobile Number for SMS</Label>
                </Col>
                <Col xs={3}>
                    <Label className="g3-label ps-1">Send AI SMS</Label>
                </Col>
            </Row>
            {rows.map(row => (
                <GenerateAiAppraisalRow
                    model={row}
                    modelChanged={handleRowChanged}
                    key={row.uuid}
                    remove={handleRemoveRow}
                />
            ))}

            <Row className="mt-3">
                <Col xs={12}>
                    <IconButton variant="white" faIconString="fa fa-plus" tooltip="Add" onClick={handleNewRow} />
                    <strong className="ms-2">Add More</strong>
                </Col>
            </Row>

            <IconButton className="mt-3" block text="Send Appraisals" onClick={handleSend} />
        </>
    );
};

export default GenerateAiAppraisalsForm;
