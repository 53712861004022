import { useSelector } from "react-redux";
import { SessionStoreState } from "Types/SessionStoreState";

const useSession = () => {
    const session = useSelector((state: SessionStoreState) => state?.session);

    return session;
};

export { useSession };
