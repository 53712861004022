import { faTasks } from "@fortawesome/free-solid-svg-icons";
import { PermissionGroups } from "Constants/PermissionGroups";
import useRoles from "Hooks/UseRoles";
import { useMemo } from "react";
import SidebarNavItem from "../SidebarNavItem/SidebarNavItem";

const NoValuationAdminLinks = () => {
    const { roles } = useRoles();

    const show = useMemo(() => {
        if (!PermissionGroups.isNoValuationAdminGroup(roles)) {
            return false;
        }

        return true;
    }, [roles]);

    return (
        <>
            {show && (
                <>
                    <SidebarNavItem
                        navLink={{
                            label: "Tasks",
                            icon: faTasks,
                            to: "/tasks",
                        }}
                    />
                </>
            )}
        </>
    );
};

export default NoValuationAdminLinks;
