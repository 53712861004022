import { faBell } from "@fortawesome/free-solid-svg-icons";
import useRoles from "Hooks/UseRoles";
import { useMemo } from "react";
import { ROLES } from "Types/Enums/Roles";
import SidebarNavItem from "../SidebarNavItem/SidebarNavItem";

const RetailProfilesLink = () => {
    const requiredRoles = useMemo(
        () =>
            new Set([
                ROLES.ORGANISATION_MODERATOR as string,
                ROLES.ORGANISATION_SALES_MANAGER as string,
                ROLES.SYSTEM_ADMINISTRATOR as string,
            ]),
        []
    );

    const { roles } = useRoles();

    const show = useMemo(() => {
        return (roles ?? []).some(r => requiredRoles.has(r));
    }, [requiredRoles, roles]);

    return (
        <>
            {show && (
                <SidebarNavItem
                    navLink={{
                        icon: faBell,
                        label: "Retail Profiles",
                        to: "/retail",
                    }}
                />
            )}
        </>
    );
};

export default RetailProfilesLink;
