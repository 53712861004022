import { LookupModel } from "Types/LookupModel";

export interface LeadPriorityTableRow {
    leadId: number;
    callType: LeadPriorityCallType;
    added: string;
    firstResponse: string | null;
    callAttempts: number;
    vrm: string;
    currentValuation: number;
    distance: number;
    assignedTo: string;
    status: LookupModel;
    mobile: string;
    currentLeadNoteId?: number;
    smsSent?: string;
    priorityScore?: number;
}

export enum LeadPriorityCallType {
    New,
    ReAttempt,
}
