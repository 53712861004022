import { faCommentDots } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UserSettingsContext } from "Providers/UserSettings/UserSettingsProvider";
import React, { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Badge } from "reactstrap";
import useDmwToasts from "Hooks/UseDmwToasts";
import notificationService from "Services/NotificationService";

export default function NotificationsLink() {
    const [count, setCount] = useState<number>(0);

    const { errorToast } = useDmwToasts();

    const { state } = useContext(UserSettingsContext);

    useEffect(() => {
        notificationService
            .getUnacknowledgedCount()
            .then(res => setCount(res.count))
            .catch(errorToast);
    }, [errorToast, state.notificationCountRefreshToken]);

    return (
        <NavLink className={"sidebar-link"} to={"/notifications"}>
            <FontAwesomeIcon icon={faCommentDots} />
            <span className={"ms-3"}>Notifications</span>
            {!!count && (
                <Badge className={"ms-2"} color={"primary"} style={{ padding: "0.35em 0.65em", width: "initial" }}>
                    {count}
                </Badge>
            )}
        </NavLink>
    );
}
