import { IconButton, InfoTag } from "@g3r-developers/g3-common";
import { ActivityHistory } from "Components/ContactActivity/ActivityHistory";
import { LeadStatusCell } from "Components/Leads/LeadStatusCell";
import DirectionsMap from "Components/Map/DirectionsMap";
import leadService from "Services/LeadService";
import { dateTimeFormat } from "Services/Utils";
import { GeoCoordinates } from "Types/GeoCoordinates";
import { LeadViewModel } from "Types/lead/LeadViewModel";
import { LeadPriorityCallType, LeadPriorityTableRow } from "Types/lead/Priority/LeadPriorityTableRow";
import { DateTime } from "luxon";
import { useCallback, useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { DialerAccordion } from "./DialerAccordion";
import { TimeToFirstResponseValue } from "./TimeToFirstResponseValue";

export interface DialerExpandableRowProps {
    data?: LeadPriorityTableRow;
}

export const DialerExpandableRow = ({ data }: DialerExpandableRowProps) => {
    const [lead, setLead] = useState<LeadViewModel>(undefined);

    const loadLead = useCallback(async (leadId: number) => {
        const l = await leadService.getLeadView(leadId);
        setLead(l);
    }, []);

    useEffect(() => {
        if (!data) {
            return;
        }

        loadLead(data.leadId);
    }, [data, loadLead]);

    const handleAssign = useCallback(async () => {
        await leadService.assign(data.leadId);
        loadLead(data.leadId);
    }, [data.leadId, loadLead]);

    return (
        <>
            {lead && (
                <Row className="p-3">
                    <Col xs={3}>
                        <Row>
                            <h5 className="text-bold">Details</h5>
                            <InfoTag value={`${lead.firstName} ${lead.lastName}`} title="Name" />
                            <InfoTag value={lead.mobile} title="Number" />
                            <InfoTag value={lead.postcode} title="Postcode" />
                            <InfoTag value={lead.distanceToBranch ?? "0"} title="Distance to Branch" />
                            <InfoTag value={lead.vrm} title="VRM" />
                            <InfoTag value={lead.details.make.makeName} title="Make" />
                            <InfoTag value={lead.details.model.modelName} title="Model" />
                            <InfoTag value={lead.leadOrigin?.displayName} title="Source" />
                            <InfoTag value={lead.originOrganisation?.name} title="Origin Org" />
                            <InfoTag value={data?.assignedTo} title="Assigned To" />
                            <InfoTag value={DateTime.fromISO(lead.added).toFormat(dateTimeFormat)} title="Lead Added" />
                            <InfoTag
                                value={
                                    <>
                                        <TimeToFirstResponseValue
                                            firstResponse={data.firstResponse}
                                            added={data.added}
                                        />
                                    </>
                                }
                                title="Time to First Response"
                            />

                            <DirectionsMap
                                start={{ latitude: lead.latitude, longitude: lead.longitude } as GeoCoordinates}
                                destination={
                                    { latitude: lead.branchLatitude, longitude: lead.branchLongitude } as GeoCoordinates
                                }
                                onRoute={() => {}}
                            />
                        </Row>
                    </Col>
                    <Col xs={9}>
                        <div className="d-flex align-items-end justify-content-end">
                            {lead.user === null && (
                                <IconButton variant="secondary" text="Assign to Me" onClick={handleAssign} />
                            )}
                            <IconButton
                                variant="secondary"
                                text="View Lead"
                                className="ms-2"
                                url={`/leads/${data.leadId}`}
                                targetString="__blank"
                            />
                        </div>
                        <Row>
                            <Col xs={6}>
                                <div style={{ maxHeight: "250px", overflowY: "scroll" }}>
                                    <ActivityHistory leadId={data.leadId} handleReload={() => {}} />
                                </div>
                            </Col>
                            <Col xs={6}>
                                <div className="d-flex flex-column">
                                    <h2>
                                        Valuation: <strong>{lead.generatedValuation.amountString}</strong>
                                    </h2>
                                    Valuaton Date: {lead.generatedValuation.addedString}
                                    <LeadStatusCell status={data.status} />
                                    <InfoTag
                                        className="mt-2 w-100"
                                        title="Call Type"
                                        value={data.callType === LeadPriorityCallType.New ? "New" : "Re-Attempt"}
                                    />
                                    <InfoTag title="Call Attempts" value={data.callAttempts} />
                                </div>
                            </Col>
                        </Row>
                        <DialerAccordion data={data} lead={lead} />
                    </Col>
                </Row>
            )}
        </>
    );
};
