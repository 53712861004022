import DropdownModel from "Types/DropdownModel";
import { BranchType } from "Types/Enums/BranchType";
import { LOOKUP_TYPES } from "Types/Enums/LookupTypes";
import api from "./Api";

const appraisalProviders = async (query?: string): Promise<DropdownModel[]> => {
    return api.get<DropdownModel[]>("dropdown/appraisal-provider", { query });
};

export const attribvals = (type: string, query: string): Promise<DropdownModel[]> => {
    return api.get<DropdownModel[]>(
        `dropdown/attribvals/${type}`,
        {
            query: query,
        },
        true
    );
};

const availableOrganisationIntegrationTypes = async (query: string): Promise<DropdownModel[]> =>
    api.get<DropdownModel[]>("dropdown/lookup-type/organisation-integration-type", { query: query });

const branches = async (query?: string): Promise<DropdownModel[]> => {
    return api.get<DropdownModel[]>("dropdown/branch", { query });
};

export const branchesAuction = async (): Promise<DropdownModel[]> => {
    return api.get<DropdownModel[]>(`dropdown/branch/type?queryId=${BranchType.Auction}`);
};

const branchesG3Auction = async (query?: string): Promise<DropdownModel[]> => {
    return api.get<DropdownModel[]>(`dropdown/branch/g3/auction`, { query: query });
};

const branchesAuctionIncludingG3 = async (): Promise<DropdownModel[]> => {
    return Promise.all([branchesG3Auction(), branchesAuction()]).then(([g3Branches, myOrgBranches]) =>
        g3Branches.concat(myOrgBranches)
    );
};

const branchesIncludingG3 = async (query?: string): Promise<DropdownModel[]> => {
    return Promise.all([branchesG3Auction(query), branches(query)]).then(([g3Branches, myOrgBranches]) =>
        myOrgBranches.concat(g3Branches)
    );
};

export const branchesRetail = async (): Promise<DropdownModel[]> => {
    return api.get<DropdownModel[]>(`dropdown/branch/type?queryId=${BranchType.Retail}`);
};

export const branchesUsers = async (branchId: number, query?: string): Promise<DropdownModel[]> => {
    return api.get<DropdownModel[]>(`dropdown/branch/${branchId}/users`, { query: query });
};

const contactActivityOutcomes = (query?: string): Promise<DropdownModel[]> => {
    return api.get<DropdownModel[]>(`dropdown/lookup-type/contact-activity-outcome/lookup`, { query: query });
};

const contactActivityTypes = (query?: string): Promise<DropdownModel[]> => {
    return api.get<DropdownModel[]>(`dropdown/lookup-type/contact-activity-type/lookup`, { query: query });
};

const derivatives = async (modelIds: number[], query?: string): Promise<DropdownModel[]> => {
    return api.get<DropdownModel[]>("dropdown/derivative", { modelIds, query });
};

const lookups = async (lookupTypeId: LOOKUP_TYPES, query?: string): Promise<DropdownModel[]> => {
    return api.get<DropdownModel[]>(`dropdown/lookup-type/${lookupTypeId}/lookup`, { query });
};

export const makes = (query?: string): Promise<DropdownModel[]> => {
    return api.get<DropdownModel[]>(`dropdown/make`, { query: query });
};

export const models = (makeIds: number[], query?: string): Promise<DropdownModel[]> => {
    return api.get<DropdownModel[]>(`dropdown/model`, { makeIds: makeIds, query: query });
};

const users = async (query?: string): Promise<DropdownModel[]> => {
    return api.get<DropdownModel[]>("dropdown/user", { query });
};

const dropdownService = {
    appraisalProviders,
    attribvals,
    availableOrganisationIntegrationTypes,
    branches,
    branchesAuction,
    branchesAuctionIncludingG3,
    branchesG3Auction,
    branchesIncludingG3,
    branchesRetail,
    branchesUsers,
    contactActivityOutcomes,
    contactActivityTypes,
    derivatives,
    lookups,
    makes,
    models,
    users,
};

export default dropdownService;
