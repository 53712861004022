import { createContext, useReducer } from "react";
import { OrganisationModel } from "Types/Organisation/OrganisationModel";
import { UserSettingsReducer } from "./Reducer";

// Define strongly-typed state of the context
export type UserSettingsState = {
    organisation: OrganisationModel;
    logoLoadedHash: number;
    notificationCountRefreshToken: Record<string, never>;
};

// Define an initial state for the context
export const initialState: UserSettingsState = {
    organisation: undefined,
    logoLoadedHash: Date.now(),
    notificationCountRefreshToken: {},
};

// Define strongly-type actions for reducer
export type UserSettingsActions =
    | { type: "SET_ORGANISATION"; organisation: OrganisationModel }
    | { type: "SET_ORGANISATION_LOGO_URL"; logoStorageUrl: string }
    | { type: "REFRESH_NOTIFICATION_COUNT" };

// Define Context
export const UserSettingsContext = createContext<{
    state: typeof initialState;
    dispatch: (action: UserSettingsActions) => void;
}>({ state: initialState, dispatch: () => {} });

// Define Provider
const UserSettingsContextProvider = (props: any) => {
    const [state, dispatch] = useReducer(UserSettingsReducer, initialState);
    return <UserSettingsContext.Provider value={{ state, dispatch }}>{props.children}</UserSettingsContext.Provider>;
};

export default UserSettingsContextProvider;
